import React, { useEffect, useState } from 'react';
import { Link, Outlet, useNavigate } from 'react-router-dom';
import { FooterCustom } from '../Footer';
import { store } from "../../redux/store";
import api_app from "../../apis/api_app";
import { createUser } from "../../redux/user/slice";
import { useDispatch } from "react-redux";
import * as Styled from './styles'
import { keycloak, hasGroup } from "../../keycloak";

const Submenu = (props: { show: boolean; children: React.ReactNode }) => (
  <Styled.Submenu {...props}>{props.children}</Styled.Submenu>
);

const SideBar = () => {
  const [isOpen, setIsOpen] = useState(true);
  const [isNav, setIsNav] = useState(true);
  const [profissional, setProfissional] = useState(false);
  const [secretaria, setSecretaria] = useState(false);
  const loggedUserId = store.getState().user?.user?.id

  let navigate = useNavigate();
  let dispach = useDispatch();

  useEffect(() => {
    if (keycloak.authenticated && loggedUserId === undefined) {
      window.location.reload();
    }
    api_app.findByPessoa(keycloak?.idTokenParsed?.email).then(result => {
      if (result) {
        setProfissional(hasGroup("/profissional") || false);
        setSecretaria(hasGroup("/secretariado") || false);

        dispach(createUser(result));
      } else {
        navigate('/registro');
      }
    });
  }, []);

  const [
    showDadosPessoais, setShowDadosPessoais,
  ] = useState(false);
  const [showSecretariado, setShowSecretariado] = useState(false);

  const [
    showDadosProfissionais, setShowDadosProfissionais,
  ] = useState(false);

  const toggleSidebar = () => {
    setIsOpen(!isOpen);
    setShowDadosPessoais(false);
  };

  const toggleDadosPessoais = () => {
    setShowDadosPessoais(!showDadosPessoais);
    if (!isOpen) {
      setIsOpen(true);
    }
  };
  const toggleDadosProfissionais = () => {
    setShowDadosProfissionais(!showDadosProfissionais);
    if (!isOpen) {
      setIsOpen(true);
    }
  };

  const toggleSecretariado = () => {
    setShowSecretariado(!showSecretariado);
    if (!isOpen) setIsOpen(true);
  };

  const closeSidebar = () => {
    setIsOpen(false);
    setShowDadosPessoais(false);
    setShowDadosProfissionais(false);
  };

  return (
    <>

      {isNav && (
        <div>
          <aside
            id="drawer-navigation"
            className={`fixed top-16 left-0 z-40 ${isOpen ? 'w-64' : 'w-16'} h-screen pt-2 transition-width duration-300 bg-white border-r border-gray-200 dark:bg-gray-800 dark:border-gray-700`}
            aria-label="Sidebar"
          >
            <div className="h-full flex flex-col justify-between px-3 pb-4 overflow-y-auto">
              <div>
                <ul className="space-y-2 font-medium">

                  {/* Dados pessoais */}
                  <li>
                    <button
                      type="button"
                      className="flex items-center w-full p-2 text-base text-gray-900 transition duration-75 rounded-lg group hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700"
                      onClick={toggleDadosPessoais}
                      aria-expanded={showDadosPessoais ? 'true' : 'false'}
                    >
                      {isOpen || showDadosPessoais ? (
                        <>
                          <svg className="w-6 h-6 text-gray-800 dark:text-white"
                            aria-hidden="true" xmlns="http://www.w3.org/2000/svg"
                            fill="currentColor" viewBox="0 0 18 20">
                            <path
                              d="M16 0H4a2 2 0 0 0-2 2v1H1a1 1 0 0 0 0 2h1v2H1a1 1 0 0 0 0 2h1v2H1a1 1 0 0 0 0 2h1v2H1a1 1 0 0 0 0 2h1v1a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2Zm-5.5 4.5a3 3 0 1 1 0 6 3 3 0 0 1 0-6ZM13.929 17H7.071a.5.5 0 0 1-.5-.5 3.935 3.935 0 1 1 7.858 0 .5.5 0 0 1-.5.5Z" />
                          </svg>
                          <span
                            className="flex-1 ms-3 text-left rtl:text-right whitespace-nowrap">Meus Dados Pessoais</span>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth="1.5"
                            stroke="currentColor"
                            className={`w-6 h-6 ml-2 transform ${showDadosPessoais ? 'rotate-90' : 'rotate-0'} text-gray-800 dark:text-white`}
                          >
                            <path strokeLinecap="round" strokeLinejoin="round"
                              d="M8.25 4.5l7.5 7.5-7.5 7.5" />
                          </svg>
                        </>
                      ) : (
                        <svg
                          className="w-6 h-6 text-gray-800 dark:text-white"
                          aria-hidden="true"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="currentColor"
                          viewBox="0 0 18 20"
                        >
                          <path
                            d="M16 0H4a2 2 0 0 0-2 2v1H1a1 1 0 0 0 0 2h1v2H1a1 1 0 0 0 0 2h1v2H1a1 1 0 0 0 0 2h1v2H1a1 1 0 0 0 0 2h1v1a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2Zm-5.5 4.5a3 3 0 1 1 0 6 3 3 0 0 1 0-6ZM13.929 17H7.071a.5.5 0 0 1-.5-.5 3.935 3.935 0 1 1 7.858 0 .5.5 0 0 1-.5.5Z" />
                        </svg>
                      )}
                    </button>
                    <ul
                      id="dados-pessoais"
                      className={`${showDadosPessoais ? 'block' : 'hidden'} py-2 space-y-2 pl-8`}
                    >
                      <li>
                        <Link
                          to="/perfil"
                          className="flex items-center w-full p-2 text-gray-900 transition duration-75 rounded-lg group hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700"

                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth="1.5"
                            stroke="currentColor"
                            className="w-6 h-6 mr-2"
                          >
                            <path strokeLinecap="round" strokeLinejoin="round"
                              d="M15.75 6a3.75 3.75 0 1 1-7.5 0 3.75 3.75 0 0 1 7.5 0ZM4.501 20.118a7.5 7.5 0 0 1 14.998 0A17.933 17.933 0 0 1 12 21.75c-2.676 0-5.216-.584-7.499-1.632Z" />
                          </svg>
                          <p className="text-base text-gray-900 dark:text-white">Meu
                            Perfil</p>
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/agendamento-de-consulta"
                          className="flex items-center w-full p-2 text-gray-900 transition duration-75 rounded-lg group hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700"

                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth="1.5"
                            stroke="currentColor"
                            className="w-6 h-6 mr-2"
                          >
                            <path strokeLinecap="round" strokeLinejoin="round"
                              d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 0 1 2.25-2.25h13.5A2.25 2.25 0 0 1 21 7.5v11.25m-18 0A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75m-18 0v-7.5A2.25 2.25 0 0 1 5.25 9h13.5A2.25 2.25 0 0 1 21 11.25v7.5m-9-6h.008v.008H12v-.008ZM12 15h.008v.008H12V15Zm0 2.25h.008v.008H12v-.008ZM9.75 15h.008v.008H9.75V15Zm0 2.25h.008v.008H9.75v-.008ZM7.5 15h.008v.008H7.5V15Zm0 2.25h.008v.008H7.5v-.008Zm6.75-4.5h.008v.008h-.008v-.008Zm0 2.25h.008v.008h-.008V15Zm0 2.25h.008v.008h-.008v-.008Zm2.25-4.5h.008v.008H16.5v-.008Zm0 2.25h.008v.008H16.5V15Z" />
                          </svg>
                          <p className="text-base text-gray-900 dark:text-white">
                            Agendamento De Consultas
                          </p>
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/pronto-atendimento"
                          className="flex items-center w-full p-2 text-gray-900 transition duration-75 rounded-lg group hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700"

                        >
                          <svg xmlns="http://www.w3.org/2000/svg" fill="none"
                            viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor"
                            className="w-5 h-5 mr-2">
                            <path strokeLinecap="round" strokeLinejoin="round"
                              d="M20.25 8.511c.884.284 1.5 1.128 1.5 2.097v4.286c0 1.136-.847 2.1-1.98 2.193-.34.027-.68.052-1.02.072v3.091l-3-3c-1.354 0-2.694-.055-4.02-.163a2.115 2.115 0 0 1-.825-.242m9.345-8.334a2.126 2.126 0 0 0-.476-.095 48.64 48.64 0 0 0-8.048 0c-1.131.094-1.976 1.057-1.976 2.192v4.286c0 .837.46 1.58 1.155 1.951m9.345-8.334V6.637c0-1.621-1.152-3.026-2.76-3.235A48.455 48.455 0 0 0 11.25 3c-2.115 0-4.198.137-6.24.402-1.608.209-2.76 1.614-2.76 3.235v6.226c0 1.621 1.152 3.026 2.76 3.235.577.075 1.157.14 1.74.194V21l4.155-4.155" />
                          </svg>
                          <p className="text-base text-gray-900 dark:text-white">Pronto
                            Atendimento</p>


                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/prontuario"
                          className="flex items-center w-full p-2 text-gray-900 transition duration-75 rounded-lg group hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700"

                        >
                          <svg xmlns="http://www.w3.org/2000/svg" fill="none"
                            viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor"
                            className="w-5 h-5 mr-2">
                            <path strokeLinecap="round" strokeLinejoin="round"
                              d="M15.75 17.25v3.375c0 .621-.504 1.125-1.125 1.125h-9.75a1.125 1.125 0 0 1-1.125-1.125V7.875c0-.621.504-1.125 1.125-1.125H6.75a9.06 9.06 0 0 1 1.5.124m7.5 10.376h3.375c.621 0 1.125-.504 1.125-1.125V11.25c0-4.46-3.243-8.161-7.5-8.876a9.06 9.06 0 0 0-1.5-.124H9.375c-.621 0-1.125.504-1.125 1.125v3.5m7.5 10.375H9.375a1.125 1.125 0 0 1-1.125-1.125v-9.25m12 6.625v-1.875a3.375 3.375 0 0 0-3.375-3.375h-1.5a1.125 1.125 0 0 1-1.125-1.125v-1.5a3.375 3.375 0 0 0-3.375-3.375H9.75" />
                          </svg>
                          <p className="text-base text-gray-900 dark:text-white"> Meu
                            Prontuário</p>
                        </Link>
                      </li>
                    </ul>
                  </li>

                  {profissional && (
                    <li>
                      <button
                        type="button"
                        className="flex items-center w-full p-2 text-base text-gray-900 transition duration-75 rounded-lg group hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700"
                        onClick={toggleDadosProfissionais}
                        aria-expanded={showDadosProfissionais ? 'true' : 'false'}
                      >
                        {isOpen || showDadosProfissionais ? (
                          <>
                            <svg className="w-5 h-5 text-gray-800 dark:text-white"
                              aria-hidden="true" xmlns="http://www.w3.org/2000/svg"
                              fill="none" viewBox="0 0 18 20">
                              <path stroke="currentColor" strokeLinecap="round"
                                strokeLinejoin="round" strokeWidth="2"
                                d="M4 4H1m3 4H1m3 4H1m3 4H1m6.071.286a3.429 3.429 0 1 1 6.858 0M4 1h12a1 1 0 0 1 1 1v16a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1Zm9 6.5a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0Z" />
                            </svg>

                            <span
                              className="flex-1 ms-3 text-left rtl:text-right whitespace-nowrap"
                            >
                              Dados Profissionais
                            </span>

                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              strokeWidth="1.5"
                              stroke="currentColor"
                              className={`w-4 h-5 ml-2 transform ${showDadosProfissionais ? 'rotate-90' : 'rotate-0'} text-gray-800 dark:text-white`}
                            >
                              <path strokeLinecap="round" strokeLinejoin="round"
                                d="M8.25 4.5l7.5 7.5-7.5 7.5" />
                            </svg>
                          </>
                        ) : (
                          <>
                            <svg className="w-6 h-6 text-gray-800 dark:text-white"
                              aria-hidden="true" xmlns="http://www.w3.org/2000/svg"
                              fill="none" viewBox="0 0 18 20">
                              <path stroke="currentColor" strokeLinecap="round"
                                strokeLinejoin="round" strokeWidth="2"
                                d="M4 4H1m3 4H1m3 4H1m3 4H1m6.071.286a3.429 3.429 0 1 1 6.858 0M4 1h12a1 1 0 0 1 1 1v16a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1Zm9 6.5a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0Z" />
                            </svg>
                          </>
                        )}
                      </button>
                      <ul
                        id="dados-profissionais"
                        className={`${showDadosProfissionais ? 'block' : 'hidden'} py-2 space-y-2 pl-8`}
                      >
                        <li>
                          <Link
                            to="/dados-profissionais/agenda"
                            className="flex items-center w-full p-2 text-gray-900 transition duration-75 rounded-lg group hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700"

                          >
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none"
                              viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor"
                              className="w-6 h-6 mr-2">
                              <path strokeLinecap="round" strokeLinejoin="round"
                                d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 0 1 2.25-2.25h13.5A2.25 2.25 0 0 1 21 7.5v11.25m-18 0A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75m-18 0v-7.5A2.25 2.25 0 0 1 5.25 9h13.5A2.25 2.25 0 0 1 21 11.25v7.5m-9-6h.008v.008H12v-.008ZM12 15h.008v.008H12V15Zm0 2.25h.008v.008H12v-.008ZM9.75 15h.008v.008H9.75V15Zm0 2.25h.008v.008H9.75v-.008ZM7.5 15h.008v.008H7.5V15Zm0 2.25h.008v.008H7.5v-.008Zm6.75-4.5h.008v.008h-.008v-.008Zm0 2.25h.008v.008h-.008V15Zm0 2.25h.008v.008h-.008v-.008Zm2.25-4.5h.008v.008H16.5v-.008Zm0 2.25h.008v.008H16.5V15Z" />
                            </svg>

                            <p className="text-base text-gray-900 dark:text-white">Agenda</p>
                          </Link>
                        </li>
                        <li>
                          <Link
                            to="/dados-profissionais/meus-pacientes"
                            className="flex items-center w-full p-2 text-gray-900 transition duration-75 rounded-lg group hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700"

                          >
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="size-6 mr-2">
                              <path strokeLinecap="round" strokeLinejoin="round" d="M18 18.72a9.094 9.094 0 0 0 3.741-.479 3 3 0 0 0-4.682-2.72m.94 3.198.001.031c0 .225-.012.447-.037.666A11.944 11.944 0 0 1 12 21c-2.17 0-4.207-.576-5.963-1.584A6.062 6.062 0 0 1 6 18.719m12 0a5.971 5.971 0 0 0-.941-3.197m0 0A5.995 5.995 0 0 0 12 12.75a5.995 5.995 0 0 0-5.058 2.772m0 0a3 3 0 0 0-4.681 2.72 8.986 8.986 0 0 0 3.74.477m.94-3.197a5.971 5.971 0 0 0-.94 3.197M15 6.75a3 3 0 1 1-6 0 3 3 0 0 1 6 0Zm6 3a2.25 2.25 0 1 1-4.5 0 2.25 2.25 0 0 1 4.5 0Zm-13.5 0a2.25 2.25 0 1 1-4.5 0 2.25 2.25 0 0 1 4.5 0Z" />
                            </svg>


                            <p className="text-base text-gray-900 dark:text-white">Meus Pacientes</p>
                          </Link>
                        </li>
                        <li>
                          <Link
                            to={`/agendamento-de-consulta/agendar-consulta/${loggedUserId}?profissional_id=${loggedUserId}`}
                            className="flex items-center w-full p-2 text-gray-900 transition duration-75 rounded-lg group hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              strokeWidth="1.5"
                              stroke="currentColor"
                              className="w-6 h-6 mr-2"
                            >
                              <path strokeLinecap="round" strokeLinejoin="round"
                                d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 0 1 2.25-2.25h13.5A2.25 2.25 0 0 1 21 7.5v11.25m-18 0A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75m-18 0v-7.5A2.25 2.25 0 0 1 5.25 9h13.5A2.25 2.25 0 0 1 21 11.25v7.5m-9-6h.008v.008H12v-.008ZM12 15h.008v.008H12V15Zm0 2.25h.008v.008H12v-.008ZM9.75 15h.008v.008H9.75V15Zm0 2.25h.008v.008H9.75v-.008ZM7.5 15h.008v.008H7.5V15Zm0 2.25h.008v.008H7.5v-.008Zm6.75-4.5h.008v.008h-.008v-.008Zm0 2.25h.008v.008h-.008V15Zm0 2.25h.008v.008h-.008v-.008Zm2.25-4.5h.008v.008H16.5v-.008Zm0 2.25h.008v.008H16.5V15Z" />
                            </svg>
                            <p className="text-base text-gray-900 dark:text-white">
                              Agendamento De Consultas
                            </p>
                          </Link>
                        </li>
                        <li>
                          <Styled.MenuLinkContainer to="/dados-profissionais/secretariado">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6 mr-2">
                              <path strokeLinecap="round" strokeLinejoin="round" d="M9 3.75h6c.414 0 .75.336.75.75v1.5c0 .414-.336.75-.75.75H9a.75.75 0 0 1-.75-.75v-1.5c0-.414.336-.75.75-.75zM5.25 6A2.25 2.25 0 0 1 7.5 3.75h9A2.25 2.25 0 0 1 18.75 6v13.5A2.25 2.25 0 0 1 16.5 21.75h-9A2.25 2.25 0 0 1 5.25 19.5V6zM9 9h6M9 12h6M9 15h3" />
                            </svg>

                            <Styled.SecretariatLabel>
                              Secretariado
                            </Styled.SecretariatLabel>
                          </Styled.MenuLinkContainer>
                        </li>
                      </ul>
                    </li>
                  )}

                  {secretaria && (
                    <Styled.ListItem>
                      <Styled.Button
                        onClick={toggleSecretariado}
                        aria-expanded={showSecretariado ? "true" : "false"}
                      >
                        <Styled.SvgIcon
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth="1.5"
                          stroke="currentColor"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M9 3.75h6c.414 0 .75.336.75.75v1.5c0 .414-.336.75-.75.75H9a.75.75 0 0 1-.75-.75v-1.5c0-.414.336-.75.75-.75zM5.25 6A2.25 2.25 0 0 1 7.5 3.75h9A2.25 2.25 0 0 1 18.75 6v13.5A2.25 2.25 0 0 1 16.5 21.75h-9A2.25 2.25 0 0 1 5.25 19.5V6zM9 9h6M9 12h6M9 15h3"
                          />
                        </Styled.SvgIcon>
                        <span className="flex-1 ms-3">Secretariado</span>
                        <Styled.SvgIcon
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth="1.5"
                          stroke="currentColor"
                          className={showSecretariado ? "rotate" : ""}
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M8.25 4.5l7.5 7.5-7.5 7.5"
                          />
                        </Styled.SvgIcon>
                      </Styled.Button>
                      <Submenu show={showSecretariado}>
                        <Styled.ListItem>
                          <Styled.StyledLink to="/secretariado/meus-profissionais">
                            <Styled.SvgIcon
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              strokeWidth="1.5"
                              stroke="currentColor"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M18 18.72a9.094 9.094 0 0 0 3.741-.479 3 3 0 0 0-4.682-2.72m.94 3.198.001.031c0 .225-.012.447-.037.666A11.944 11.944 0 0 1 12 21c-2.17 0-4.207-.576-5.963-1.584A6.062 6.062 0 0 1 6 18.719m12 0a5.971 5.971 0 0 0-.941-3.197m0 0A5.995 5.995 0 0 0 12 12.75a5.995 5.995 0 0 0-5.058 2.772m0 0a3 3 0 0 0-4.681 2.72 8.986 8.986 0 0 0 3.74.477m.94-3.197a5.971 5.971 0 0 0-.94 3.197M15 6.75a3 3 0 1 1-6 0 3 3 0 0 1 6 0Zm6 3a2.25 2.25 0 1 1-4.5 0 2.25 2.25 0 0 1 4.5 0Zm-13.5 0a2.25 2.25 0 1 1-4.5 0 2.25 2.25 0 0 1 4.5 0Z"
                              />
                            </Styled.SvgIcon>
                            <span>Meus Profissionais</span>
                          </Styled.StyledLink>
                        </Styled.ListItem>
                      </Submenu>
                      <Submenu show={showSecretariado}>
                        <Styled.ListItem>
                          <Styled.StyledLink to="/secretariado/meus-profissionais?modal=open">
                            <Styled.SvgIcon
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              strokeWidth="1.5"
                              stroke="currentColor"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M12 9v6m3-3H9m12 0a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                              />
                            </Styled.SvgIcon>
                            <span>Registrar Usuário</span>
                          </Styled.StyledLink>
                        </Styled.ListItem>
                      </Submenu>
                    </Styled.ListItem>
                  )}
                </ul>
              </div>
            </div>
          </aside>

          <button
            type="button"
            onClick={toggleSidebar}
            aria-controls="drawer-navigation"
            className={`fixed bottom-4 left-2 z-50 bg-white p-2 rounded-full shadow-lg text-gray-900 dark:bg-gray-800 dark:text-white dark:hover:bg-gray-700 dark:hover:text-white hover:bg-gray-200 transition duration-300 ${isOpen ? 'hidden' : ''}`}
          >
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5"
              stroke="currentColor" className="size-6">
              <path strokeLinecap="round" strokeLinejoin="round"
                d="m5.25 4.5 7.5 7.5-7.5 7.5m6-15 7.5 7.5-7.5 7.5" />
            </svg>

          </button>

          <button
            type="button"
            onClick={closeSidebar}
            aria-label="Fechar Sidebar"
            className={`fixed bottom-4 left-2 z-50 bg-white p-2 rounded-full shadow-lg text-gray-900 dark:bg-gray-800 dark:text-white dark:hover:bg-gray-700 dark:hover:text-white hover:bg-gray-200 transition duration-300 ${isOpen ? '' : 'hidden'}`}
          >
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5"
              stroke="currentColor" className="size-6">
              <path strokeLinecap="round" strokeLinejoin="round"
                d="m18.75 4.5-7.5 7.5 7.5 7.5m-6-15L5.25 12l7.5 7.5" />
            </svg>

          </button>
        </div>
      )}
      <div
        className="flex flex-col min-h-screen transition-all duration-300 bg-white dark:bg-gray-800 dark:text-white">
        <div className={`flex-grow ${isOpen ? 'ml-64' : 'ml-16'} p-4`}>
          <div className="p-4 border-dashed rounded-lg mt-14 bg-white dark:bg-gray-800 dark:border-gray-700">
            <Outlet />
          </div>
        </div>
        <div
          className={`p-4 border-t border-gray-200 dark:border-gray-700 ${isOpen ? 'ml-64' : 'ml-16'} bg-white dark:bg-gray-800`}>
          <FooterCustom />
        </div>
      </div>
    </>
  );
};

export default SideBar;
