import { FaSearch } from "react-icons/fa";
import styled, { css } from "styled-components";

export const SearchContainer = styled.div`
  align-self: flex-end;
  display: flex;
  align-items: center;
`;

const Button = styled.button`
  display: inline-flex;
  align-items: center;
  padding: 0.625rem 0.75rem;
  margin-inline-start: 0.5rem;
  font-size: 0.875rem;
  line-height: 24px;
  font-weight: 500;
  color: white;
  background-color: #3b5179;
  border-radius: 0.5rem;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:focus {
    outline: none;
    box-shadow: 0 0 0 4px rgba(59, 81, 121, 0.3);
  }

  &:hover {
    background-color: #324264;
  }
`;

export const SearchButton = styled(Button)``;

export const IconSearch = styled(FaSearch)`
  margin-right: 4px;
`

export const SpinnerContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px;
  background-color: ${({ theme }) => theme.dark ? '#111827' : '#f3f4f6'};
`;

export const TableContainer = styled.div`
  position: relative;
  overflow-x: auto;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  border-radius: 0.5rem;
  margin-top: 20px;
  max-height: 60vh;
  max-width: 75vw;
`;

export const Table = styled.table`
  width: 80%;
  font-size: 0.875rem;
  text-align: left;
  color: ${({ theme }) => theme.dark ? '#d1d5db' : '#6b7280'};
`;

export const TableHead = styled.thead`
  font-size: 0.75rem;
  color: ${({ theme }) => theme.dark ? '#d1d5db' : '#374151'};
  background-color: ${({ theme }) => theme.dark ? '#374151' : '#f9fafb'};
  text-transform: uppercase;
`;

export const TableRow = styled.tr`
  background-color: ${({ theme }) => theme.dark ? '#1f2937' : '#ffffff'};
  border-bottom: 1px solid ${({ theme }) => theme.dark ? '#374151' : '#e5e7eb'};
  transition: background-color 0.3s ease;

  &:hover {
    background-color: ${({ theme }) => theme.dark ? '#4b5563' : '#f9fafb'};
  }
`;

export const TableHeader = styled.th`
  box-sizing: border-box;
  padding: 1rem 1.5rem;
  font-weight: 500;
  color: ${({ theme }) => theme.dark ? '#ffffff' : '#1f2937'};
  white-space: nowrap;
  white-space: break-spaces;
  min-width: 150px;
`;

export const TableData = styled.td`
  padding: 1rem 1.5rem;
  white-space: break-spaces;
  min-width: 150px;
`;

export const TableEmptyMessage = styled(TableData)`
  text-align: center;
`;
