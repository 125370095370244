import { useState, useEffect } from "react";
import { getIdToken } from "../keycloak";

const ProtectedImg = ({ src, alt = "Protected Image", className = "", style = {} }: { src?: string, alt?: string, className?: string, style?: object }) => {
  const [imageSrc, setImageSrc] = useState('');

  useEffect(() => {
    const fetchImage = async () => {
      if (!src) return
      const response = await fetch(src, {
        headers: {
          Authorization: `Bearer ${getIdToken()}`,
        },
      });
      const blob = await response.blob();
      const blobUrl = URL.createObjectURL(blob);
      setImageSrc(blobUrl);

      return () => URL.revokeObjectURL(blobUrl);
    };

    fetchImage();
  }, [src]);

  return (
    <img
      src={imageSrc}
      alt={alt}
      className={`max-w-full max-h-full ${className}`}
      style={style}
    />
  );
};

export default ProtectedImg;
