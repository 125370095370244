import React, {useEffect, useRef, useState} from 'react';
import InputCustom from '../components/InputCustom';
import FileInputCustom from '../components/FileInputCustom';
import {TextAreaCustom} from '../components/TextAreaCustom';
import {store} from "../redux/store";
import {useNavigate} from "react-router-dom";
import {
    pessoa_emailModel,
    pessoa_enderecoModel,
    pessoa_especialidadeModel,
    pessoa_profissionalModel,
    pessoa_telefoneModel,
    pessoaModel
} from "../resource/request";
import {useKeycloak} from "@react-keycloak/web";
import api_app from "../apis/api_app";
import axios from 'axios';
import SelectEspecialidadeCustum from './dadospessoais/meuperfil/dadosprofissionais/especialidades/SelectEspecialidadesCustum';
import FormFiles from './dadospessoais/meuperfil/dadopessoal/FormFiles';
import {createUser} from "../redux/user/slice";
import SpinnerCustom from "../components/SpinnerCustom";
import SelectCustom from '../components/SelectCustom';
import {useDispatch} from "react-redux";
import { parseDateStringToDate } from '../helpers/date_helpers';

interface RegisterProps {
    setShouldRegister: (value: boolean) => void;
}

const Register: React.FC<RegisterProps> = ({ setShouldRegister }) => {
    const [currentStep, setCurrentStep] = useState(0);
    const [steps, setSteps] = useState(['Step 1', 'Step 2', 'Step 3']);
    let navigate = useNavigate();
    const {keycloak, initialized} = useKeycloak();
    const [user, setUser] = useState(false);
    const [profileType, setProfileType] = useState('');
    const [profissaoModel, setProfissaoModel] = useState<{ id: number }>({id: 0});
    const [especialidades, setEspecialidades] = useState({
        pessoa_id: store.getState().user?.user.id,
        flg_status: 'A',
        flg_visivel: 'S',
        uf: "",
        dat_criado_em: new Date(),
        num_especialidade_rqe: "",
        valor_atendimento_pa: 0,
        vlr_atendimento: 0,
        especialidade_id: 0
    });
    const [data, setData] = useState('');
    const [tipo_usuario_id, setTipoUsuarioId] = useState('');
    let dispach = useDispatch();

    let [pessoa, setPessoa] = useState({
        id: 0,
        nome: keycloak?.idTokenParsed?.name.toString(),
        dat_nascimento_fundacao: new Date(),
        cpf_cnpj: keycloak?.idTokenParsed?.preferred_username,
        tipo_usuario_id: 0,
        pessoa_email: [
            {
                flg_visivel: "S",
                flg_principal: "S",
                flg_status: "A",
                dat_criado_em: new Date(),
                email: keycloak?.idTokenParsed?.email,
            } as pessoa_emailModel,
        ],
        pessoa_telefone: [
            {
                telefone: "",
                flg_visivel: "S",
                flg_principal: "S",
                flg_status: "A",
            } as pessoa_telefoneModel
        ],
        pessoa_endereco: [{} as pessoa_enderecoModel],
        pessoa_especialidade: [{} as pessoa_especialidadeModel],
        pessoa_prosissional: [{} as pessoa_profissionalModel],
        formData: [{} as FormData]
    });

    let [pessoa_telefone, setPessoaTelefone] = useState({
        pessoa_telefone: [
            {
                telefone: "",
                flg_visivel: "S",
                flg_principal: "S",
                flg_status: "A",
            } as pessoa_telefoneModel
        ],
    })

    useEffect(() => {
        keycloak.onAuthSuccess = function () {
            api_app.findByPessoa(keycloak?.idTokenParsed?.email).then(result => {
                if (result) {
                    dispach(createUser(result.data));
                    if (result.pessoa_documento.length !== 0) {
                        setShouldRegister(false);
                        navigate('/');
                    }
                    console.log(store.getState().user.user);
                }

                setPessoa({
                    ...pessoa,
                    nome: keycloak?.idTokenParsed?.name.toString(),
                    cpf_cnpj: keycloak?.idTokenParsed?.preferred_username,

                    pessoa_email: [
                        {

                            flg_visivel: "S",
                            flg_principal: "S",
                            flg_status: "A",
                            dat_criado_em: new Date(),
                            email: keycloak?.idTokenParsed?.email,

                        } as pessoa_emailModel,
                    ],

                })

                setUser(true);
            });
        }
    }, []);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        const {name, value} = event.target;

        switch (name) {
            case "data": {
                setData(value);
                if (value.length > 9) {
                    setPessoa({
                        ...pessoa,
                        dat_nascimento_fundacao: parseDateStringToDate(value)
                    });
                }

                break;
            }

            case "telefone": {
                setPessoa(prevPessoa => ({
                    ...prevPessoa,
                    pessoa_telefone: [
                        {
                            ...prevPessoa.pessoa_telefone[0],
                            telefone: value
                        }
                    ]
                }));
                break;
            }

            case "tipo_usuario_id": {
                setProfileType(value);
                setTipoUsuarioId(value);
                setPessoa(prevPessoa => ({
                    ...prevPessoa,
                    tipo_usuario_id: parseInt(value)
                }));

                if (value === '3') {
                    setSteps(['Step 1', 'Step 2', 'Step 3']);
                } else if (value === '2') {
                    setSteps(['Step 1', 'Step 2', 'Step 3', 'Step 4', 'Step 5', 'Step 6']);
                }

                break;
            }

            default: {
                setPessoa(prevPessoa => ({
                    ...prevPessoa,
                    [name]: value
                }));
                break;
            }
        }
    };

    const handleChangeProfissa = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        const {name, value} = event.target;

        setPessoa({
            ...pessoa,
            pessoa_prosissional: [{
                pessoa_id: store.getState().user.user.id,
                flg_status: 'A',
                flg_visivel: 'S',
                uf_certidao_registro_profissional: '',
                carteira_conselho: '',
                certidao_registro_profissional: '',
                resumo_curriculo: value,
                certidao_regularidade: '',
                dat_criado_em: new Date()
            }]
        })
    }

    const handleChangeCep = (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        const {name, value} = event.target;

        setPessoa(prevPessoa => ({
            ...prevPessoa,
            pessoa_endereco: [{
                ...prevPessoa.pessoa_endereco?.find(x => true) as pessoa_enderecoModel,

                [name]: value,
            }],
        }));

        if (name === "cep") {
            const cep = value.replace(/\D/g, "");

            if (cep.length === 8) {
                axios.get(`https://viacep.com.br/ws/${cep}/json/`)
                    .then((response) => {
                        const data = response.data;

                        if (!data.erro) {
                            setPessoa(prevPessoa => ({
                                ...prevPessoa,
                                pessoa_endereco: [{
                                    ...prevPessoa.pessoa_endereco?.find(x => true) as pessoa_enderecoModel,
                                    cep: data.cep,
                                    pais: "Brasil",
                                    estado: data.uf,
                                    cidade: data.localidade,
                                    bairro: data.bairro,
                                    endereco: data.logradouro,
                                    flg_visivel: "S",
                                    flg_status: "A",
                                    flg_principal: "S",
                                    pessoa_id: store?.getState()?.user?.user?.id || pessoa.id
                                }],
                            }));
                        }
                    })
                    .catch((error) => {
                        console.error('Erro ao buscar o endereço:', error);
                    });
            }
        }
    };

    const handleChangeEspecialidade = (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        const {name, value} = event.target;
        switch (name) {
            case "profissao":

                setProfissaoModel(prevProfissaoModel => ({
                    ...prevProfissaoModel,
                    id: parseInt(value)
                }));
                break
            case "especialidade_id":
                setEspecialidades(prev => ({
                        ...prev,
                        especialidade_id: parseInt(value),
                        pessoa_id: store.getState().user.user.id
                    })
                );
                break;
            default:

                setEspecialidades(prevEspecialidades => ({
                    ...prevEspecialidades,
                    [name]: value
                }));
                break;
        }


    };

    const nextStep = (event: React.ChangeEvent<HTMLFormElement>) => {
        event.preventDefault();

        switch (currentStep) {
            case 0:
                api_app.criarPessoa(pessoa).then(result => {
                        dispach(createUser(result.data));
                        setPessoa(prev => ({
                            ...result.data,
                            id: result.data.id
                        }));
                        console.log(store.getState().user.user);

                        console.log(pessoa);
                        setCurrentStep((prev) => Math.min(prev + 1, steps.length - 1));

                    }
                );
                break;
            case 1:
                if (pessoa.pessoa_endereco?.length > 0) {
                    api_app.criarEndereco(pessoa.pessoa_endereco?.find(x => true)).then((x) => {
                        setCurrentStep((prev) => Math.min(prev + 1, steps.length - 1));
                    });
                }

                break;
            case 2:
            case 3:
                if (pessoa.tipo_usuario_id === 3) {
                    setShouldRegister(false);
                    navigate('/');
                } else {
                    setCurrentStep((prev) => Math.min(prev + 1, steps.length - 1));
                }
                break;
            case 4:
                api_app.criarEspecialidade(especialidades).then(x => {
                        setCurrentStep((prev) => Math.min(prev + 1, steps.length - 1));
                    }
                )
                break;
            case 5:
                api_app.criarPessoaProfissional(pessoa.id, pessoa?.pessoa_prosissional?.find(x => true)).then(result => {
                        setShouldRegister(false);
                        navigate('/');
                    }
                );
                break;
            default:
                setCurrentStep((prev) => Math.min(prev + 1, steps.length - 1));
                break;

        }


    };

    const prevStep = () => {
        setCurrentStep((prev) => Math.max(prev - 1, 0));
    };

    // if (!user) {
    //     return <div
    //         className="flex justify-center items-center h-screen">
    //         <SpinnerCustom
    //         />
    //     </div>;
    // }

    return (
        <form onSubmit={nextStep}>
            <div className={`transition-all duration-300 $ p-4 bg-white dark:bg-gray-800 dark:text-white`}>
                <div className="p-4 border-dashed rounded-lg mt-12 bg-white dark:bg-gray-800 dark:border-gray-700">
                    <div className="container mx-auto p-4">
                        <div className="flex justify-center space-x-6 mb-4 relative">
                            {steps.map((step, index) => (
                                <div key={index}
                                     className={`flex flex-col w-full items-center transition-all duration-300 ${index <= currentStep ? 'opacity-100 scale-110' : 'opacity-50 scale-100'}`}>
                                    <div className="relative">
                                        <div
                                            className={`w-8 h-8 flex items-center justify-center rounded-full ${index <= currentStep ? 'bg-blue-900 text-white' : 'bg-gray-200 text-black'}`}
                                        >
                                            {index + 1}
                                        </div>
                                        {index < steps.length - 1 && (
                                            <div
                                                className={`absolute inset-x-0 mx-auto mt-4 mb-1 h-0.5 ${index <= currentStep - 1 ? 'bg-blue-900' : 'bg-gray-300'}`}
                                                style={{width: 'calc(100% - 32px)'}}
                                            />
                                        )}
                                    </div>
                                    <p className="text-xs text-center mt-2 mb-5">
                                        {step === 'Step 1' && 'Dados Cadastrais'}
                                        {step === 'Step 2' && 'Dados de Endereço'}
                                        {step === 'Step 3' && 'Documentos Pessoais'}
                                        {step === 'Step 4' && 'Documentos Profissionais'}
                                        {step === 'Step 5' && 'Especialidades Profissionais'}
                                        {step === 'Step 6' && 'Resumo do Currículo'}
                                    </p>
                                </div>
                            ))}
                            <div
                                className="absolute top-1/2 left-0 bg-blue-900"
                                style={{
                                    height: '2px',
                                    width: `calc(${(currentStep + 1) / steps.length * 100}% - ${(steps.length - 1 - currentStep) * 4}px)`,
                                    transform: 'translateY(-50%)',
                                    zIndex: -1,
                                    transition: 'width 0.3s ease',
                                }}
                            />
                        </div>
                        <div className="mt-4">
                            {currentStep === 0 && // Dados Cadastrais
                                <div>
                                    <div className="mx-auto bg-white dark:bg-gray-800">
                                        <div className="space-y-4">
                                            <div className="mb-2 flex items-center justify-center">
                                                <div className="w-2/3">
                                                    <label
                                                        className="block mb-1 text-sm font-medium text-gray-900 dark:text-white">
                                                        Tipo de Usuário:
                                                    </label>
                                                    <select
                                                        name="tipo_usuario_id"
                                                        value={pessoa?.tipo_usuario_id.toString() || ''}
                                                        onChange={handleChange}
                                                        required
                                                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                                    >
                                                        <option value='0'> Selecione</option>
                                                        <option value="3">Paciente</option>
                                                        <option value="2">Profissional</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="mb-2 flex items-center justify-center">
                                                <div className="w-2/3">
                                                    <InputCustom
                                                        label="Nome Completo:"
                                                        value={pessoa.nome}
                                                        type="text"
                                                        name="nome"
                                                        placeholder="Nome Completo"
                                                        required
                                                    />
                                                </div>
                                            </div>
                                            <div className="mb-2 flex items-center justify-center">
                                                <div className="w-2/3">
                                                    <InputCustom
                                                        label="CPF:"
                                                        value={pessoa.cpf_cnpj}
                                                        type="text"
                                                        mask="999.999.999-99"
                                                        name="cpf_cnpj"
                                                        onChange={handleChange}
                                                        placeholder="000.000.000-00"
                                                        required
                                                    />
                                                </div>
                                            </div>
                                            <div className="mb-2 flex items-center justify-center">
                                                <div className="w-2/3">
                                                    <InputCustom
                                                        label="Telefone:"
                                                        value={pessoa.pessoa_telefone?.find((x) => true)?.telefone || ''}
                                                        type="text"
                                                        mask="(99) 9 99999999"
                                                        name="telefone"
                                                        onChange={handleChange}
                                                        placeholder="(00) 0 00000000"
                                                        required
                                                    />
                                                </div>
                                            </div>
                                            <div className="mb-2 flex items-center justify-center">
                                                <div className="w-2/3">
                                                    <InputCustom
                                                        label="Email:"
                                                        value={keycloak?.idTokenParsed?.email}
                                                        type="text"
                                                        name="email"
                                                        onChange={handleChange}
                                                        placeholder="Email"
                                                        required
                                                    />
                                                </div>
                                            </div>
                                            <div className="mb-2 flex items-center justify-center">
                                                <div className="w-2/3">
                                                    <InputCustom
                                                        label="Data de Nascimento:"
                                                        value={data}
                                                        type="text"
                                                        mask='99/99/9999'
                                                        name="data"
                                                        onChange={handleChange}
                                                        placeholder="00/00/0000"
                                                        required
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }

                            {currentStep === 1 && // Dados de Endereço
                                <div className="mx-auto bg-white dark:bg-gray-800">
                                    <div className="space-y-4">
                                        <div className="mb-2 flex items-center justify-center">
                                            <div className="w-2/3">
                                                <div>
                                                    <div className="grid gap-5 mb-6 md:grid-cols-2">
                                                        <div className="relative">
                                                            <InputCustom
                                                                label="CEP:"
                                                                value={pessoa.pessoa_endereco?.find((x) => true)?.cep || ''}
                                                                type="text"
                                                                mask="99999-999"
                                                                name="cep"
                                                                onChange={handleChangeCep}
                                                                placeholder="CEP"
                                                                required
                                                            />
                                                        </div>
                                                        <div className="relative">
                                                            <InputCustom
                                                                label="País:"
                                                                value={pessoa.pessoa_endereco?.find((x) => true)?.pais || ''}
                                                                type="text"
                                                                name="pais"
                                                                onChange={handleChangeCep}
                                                                placeholder="País"
                                                                required
                                                            />
                                                        </div>
                                                        <div className="relative">
                                                            <InputCustom
                                                                label="Estado:"
                                                                value={pessoa.pessoa_endereco?.find((x) => true)?.estado || ''}
                                                                type="text"
                                                                name="estado"
                                                                onChange={handleChangeCep}
                                                                placeholder="Estado"
                                                                required
                                                            />
                                                        </div>
                                                        <div className="relative">
                                                            <InputCustom
                                                                label="Cidade:"
                                                                value={pessoa.pessoa_endereco?.find((x) => true)?.cidade || ''}
                                                                type="text"
                                                                name="cidade"
                                                                onChange={handleChangeCep}
                                                                placeholder="Cidade"
                                                                required
                                                            />
                                                        </div>
                                                        <div className="relative">
                                                            <InputCustom
                                                                label="Bairro:"
                                                                value={pessoa.pessoa_endereco?.find((x) => true)?.bairro || ''}
                                                                type="text"
                                                                name="bairro"
                                                                onChange={handleChangeCep}
                                                                placeholder="Bairro"
                                                                required
                                                            />
                                                        </div>
                                                        <div className="relative">
                                                            <InputCustom
                                                                label="Rua:"
                                                                value={pessoa.pessoa_endereco?.find((x) => true)?.endereco || ''}
                                                                type="text"
                                                                name="endereco"
                                                                onChange={handleChangeCep}
                                                                placeholder="Rua"
                                                                required
                                                            />
                                                        </div>
                                                        <div className="relative">
                                                            <InputCustom
                                                                label="Número:"
                                                                value={pessoa.pessoa_endereco?.find((x) => true)?.numero || ''}
                                                                type="number"
                                                                name="numero"
                                                                onChange={handleChangeCep}
                                                                placeholder="Número"
                                                                required
                                                            />
                                                        </div>
                                                        <div className="relative">
                                                            <InputCustom
                                                                label="Complemento:"
                                                                value={pessoa.pessoa_endereco?.find((x) => true)?.complemento || ''}
                                                                type="text"
                                                                name="complemento"
                                                                onChange={handleChangeCep}
                                                                placeholder="Complemento"
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="relative">
                                                        <InputCustom
                                                            label="Observação:"
                                                            value={pessoa.pessoa_endereco?.find((x) => true)?.observacao || ''}
                                                            type="text"
                                                            name="observacao"
                                                            onChange={handleChangeCep}
                                                            placeholder="Observação"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }

                            {currentStep === 2 && // Documentos Pessoais
                                <div className="mx-auto bg-white dark:bg-gray-800">
                                    <div className="space-y-4">
                                        <div className="mb-2 flex items-center justify-center">
                                            <div className="w-2/3">
                                                <FormFiles
                                                    showTitle={false}
                                                    showButton={false}
                                                    usuario_id={3}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }

                            {currentStep === 3 && // Documentos Profissionais
                                <div className="mx-auto bg-white dark:bg-gray-800">
                                    <div className="space-y-4">
                                        <div className="mb-2 flex items-center justify-center">
                                            <div className="w-2/3">
                                                <div>
                                                    <FormFiles
                                                        showTitle={false}
                                                        showButton={false}
                                                        usuario_id={2}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }

                            {currentStep === 4 && // Especialidades Profissionais
                                <div className="space-y-2">
                                    <div className="mb-2 flex items-center justify-center">
                                        <div className="w-2/3">
                                            <div>
                                                <div className="grid gap-4 md:grid-cols-2">
                                                    <SelectEspecialidadeCustum
                                                        profissao_id={profissaoModel.id || 0}
                                                        value={""}
                                                        onChange={handleChangeEspecialidade}
                                                    />
                                                    <div className="relative">
                                                        <div className="flex flex-col">
                                                            <InputCustom
                                                                label="UF do registro profissional:"
                                                                value={especialidades.uf}
                                                                type="text"
                                                                name="uf"
                                                                placeholder="UF"
                                                                onChange={handleChangeEspecialidade}
                                                                required
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="relative">
                                                        <div className="flex flex-col">
                                                            <InputCustom
                                                                label="Nº Registro/Nº RQE:"
                                                                value={especialidades.num_especialidade_rqe}
                                                                type="text"
                                                                onChange={handleChangeEspecialidade}
                                                                name="num_especialidade_rqe"
                                                                placeholder="Nº Especialidades/ Nº RQE"
                                                                required
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="relative">
                                                        <div className="flex flex-col">
                                                            <InputCustom
                                                                label="Valor da Consulta:"
                                                                value={especialidades.vlr_atendimento.toString()}
                                                                type="number"
                                                                onChange={handleChangeEspecialidade}
                                                                name="vlr_atendimento"
                                                                placeholder="Valor da Consulta"
                                                                required
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }

                            {currentStep === 5 && // Resumo do Currículo
                                <div>
                                    <div className="mx-auto bg-white dark:bg-gray-800">
                                        <div className="space-y-4">
                                            <div className="mb-2 flex items-center justify-center">
                                                <div className="w-2/3">
                                                    <TextAreaCustom
                                                        label="Liste aqui um resumo do seu currículo:"
                                                        value={pessoa.pessoa_prosissional?.find((x) => true)?.resumo_curriculo || ''}
                                                        name='resumo_curriculo'
                                                        required
                                                        onChange={handleChangeProfissa}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }

                        </div>
                        <div className="flex justify-end mt-4">
                            <button
                                className="text-white bg-green-500 hover:bg-green-600 focus:ring-4 border border-white font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2"
                                style={{backgroundColor: '#a7eb51'}}
                                type={'submit'}
                            >
                                {currentStep === steps.length - 1 ? 'Salvar' : 'Próximo'}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    );
};

export default Register;
