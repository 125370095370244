import styled from 'styled-components';

export const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.75);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 50;
`;

export const ModalContainer = styled.div`
  background: #fff;
  border-radius: 8px;
  max-width: 600px;
  padding: 20px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  position: relative;
`;

export const Header = styled.div`
  display: flex;
  justify-content: end;
  align-items: center;
  border-bottom: 1px solid #ddd;
  padding-bottom: 10px;
`;

export const Title = styled.h3`
  margin: 0;
  font-size: 1.25rem;
  color: #333;
`;

export const CloseButton = styled.button`
  background: transparent;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  svg {
    width: 20px;
    height: 20px;
    color: #333;
  }
  &:hover {
    background: #f0f0f0;
  }
`;

export const Content = styled.div`
  padding: 20px 0;
  color: #444;
`;

export const Footer = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  padding-top: 10px;
  border-top: 1px solid #ddd;
`;