import styled from "styled-components";

export const Input = styled.input`
  width: 100%;
  padding: 10px;
  font-size: 14px;
  border: 1px solid #d1d5db;
  border-radius: 8px;
  background-color: #f9fafb;
  color: #1f2937;
  max-height: 42px;
  margin-top: 5px;
  margin-bottom: 15px;
  &:focus {
    outline: none;
    border-color: #3b82f6;
    box-shadow: 0 0 0 2px rgba(59, 130, 246, 0.5);
  }
`;

export const Dropdown = styled(Input).attrs({ as: 'select' })``;

export const TextArea = styled(Input).attrs({ as: 'textarea' })`
  max-height: unset;
  min-height: 66px;
`;

export const TextAreaContainer = styled.div`
  display: flex;
  width: 100%;
  gap: 10px;
`;