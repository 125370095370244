import React, { useEffect, useState } from "react";
import { TitleCustom } from "../../../components/titleCustom/TitleCustom";
import InputSearchCustom from '../../../components/InputSearchCustom'
import api_app from "../../../apis/api_app";
import * as Styled from './styles'
import SpinnerCustom from "../../../components/SpinnerCustom";
import { inputedType } from "../../../helpers/stringTypeHelper";
import { UserSearchModal } from "../../../components/userSearchModal/UserSearchModal";
import { FaXmark } from "react-icons/fa6";
import { store } from "../../../redux/store";
import { parseDateToDMYFormat } from "../../../helpers/date_helpers";

export const SecretariadoProfissional = () => {
  const user = store.getState().user.user;
  const [isLoading, setIsLoading] = useState(true)
  const [assistants, setAssistants] = useState([]);
  const [filteredAssistants, setFilteredAssistants] = useState(null)
  const [filter, setFilter] = useState({});
  const [assistantInput, setAssistantInput] = useState('');
  const [showUserSearchModal, setShowUserSearchModal] = useState(false)
  const [emptyMessage, setEmptyMessage] = useState("Não há ninguém no secretariado.")
  const assistantsToMap = filteredAssistants ?? assistants

  const fetchAssistants = async () => {
    try {
      const response = await api_app.getAssistants();
      setAssistants(response.data);
    } catch (error) {
      console.error(error);
    }
    setIsLoading(false);
  }

  useEffect(() => {
    fetchAssistants();
  }, [])

  const handleChange = (event) => {
    const { name, value } = event.target
    switch (name) {
      case 'search': {
        setAssistantInput(value)
        setFilter({
          [inputedType(value)]: value
        })
        break;
      }
      default:
        break;
    }
  }

  const handleSearch = () => {
    const key = Object.keys(filter)[0]
    if (!filter[key]) {
      setFilteredAssistants(null)
    } else {
      const result = assistants.filter(assistant => assistant[key].includes(filter[key]))
      if (result.length === 0) {
        setEmptyMessage("Sua procura não encontrou nenhum assistente.")
      }
      setFilteredAssistants(result)
    }
  }

  const handleClean = () => {
    setAssistantInput('')
    setFilteredAssistants(null)
  }

  const handleAdd = () => {
    setShowUserSearchModal(true)
  }

  const handleOnUnlinkAssistant = (assistant) => {
    const unlinkAssistant = async () => {
      try {
        await api_app.removeUserFromSecretariadoGroup(assistant.email)
        await api_app.unlinkAssistant(user.id, assistant.id)
      } catch (error) {
        console.error(error)
      }
    }

    unlinkAssistant().then(() => fetchAssistants())
    setEmptyMessage("Não há ninguém no secretariado.")
  }

  const onSelectUser = (assistant) => {
    const assistantRequest = {
      secretaria_id: assistant.id,
      profissional_id: user.id,
      secretaria_username: assistant.username
    }
    const linkAssistant = async () => {
      try {
        await api_app.addUserToSecretariadoGroup(assistant.email)
        await api_app.linkUserAsAssistant(assistantRequest)
      } catch (error) {
        console.error(error)
      }
    }

    linkAssistant().then(() => fetchAssistants())
  }

  const tableColumns = (
    <Styled.TableHead>
      <tr>
        <th scope="col" className="px-6 py-3">
          Nome
        </th>
        <th scope="col" className="px-6 py-3">
          CPF
        </th>
        <th scope="col" className="px-6 py-3">
          Data de Nascimento
        </th>
        <th scope="col" className="px-6 py-3">
          Telefone
        </th>
        <th scope="col" className="px-6 py-3">
          E-Mail
        </th>
        <th scope="col" className="px-6 py-3">
          Opções
        </th>
      </tr>
    </Styled.TableHead>
  )

  const tableContent = () => {
    if (assistantsToMap?.length === 0) {
      return (
        <Styled.TableRow key={'07'}>
          <Styled.TableEmptyMessage colSpan={14}>
            {emptyMessage}
          </Styled.TableEmptyMessage>
        </Styled.TableRow>
      )
    } else {
      return (
        assistantsToMap?.map((secretary) => {
          return (
            <Styled.TableRow key={secretary.id}>
              <Styled.TableHeader>{secretary.nome}</Styled.TableHeader>
              <Styled.TableData>{secretary.cpf_cnpj}</Styled.TableData>
              <Styled.TableData>{parseDateToDMYFormat(secretary.dat_nascimento_fundacao)}</Styled.TableData>
              <Styled.TableData>{secretary.telefone}</Styled.TableData>
              <Styled.TableData>{secretary.email}</Styled.TableData>
              <Styled.TableData>
                <button onClick={() => handleOnUnlinkAssistant(secretary)}>
                  <FaXmark />
                </button>
              </Styled.TableData>
            </Styled.TableRow>
          )
        })
      )
    }
  }

  return (
    <>
      <Styled.TitleContainer>
        <TitleCustom name='Meus Assistentes' />
      </Styled.TitleContainer>
      <Styled.HeaderOptionsContainer>
        <Styled.AddButton onClick={handleAdd}>
          <Styled.IconPlus />
          Adicionar
        </Styled.AddButton>
        <Styled.SearchContainer>
          <InputSearchCustom
            name="search"
            value={assistantInput}
            onChange={handleChange}
          />
          {assistantInput &&
            <Styled.CleanButton onClick={handleClean}>
              <Styled.IconClean />
            </Styled.CleanButton>
          }
          <Styled.SearchButton onClick={handleSearch}>
            <Styled.IconSearch />
            Pesquisar
          </Styled.SearchButton>
        </Styled.SearchContainer>
      </Styled.HeaderOptionsContainer>
      {isLoading ? (
        <Styled.SpinnerContainer>
          <SpinnerCustom />
        </Styled.SpinnerContainer>
      ) : (
        <Styled.TableContainer>
          <Styled.Table>
            {tableColumns}
            <tbody>
              {tableContent()}
            </tbody>
          </Styled.Table>
        </Styled.TableContainer>
      )}
      <UserSearchModal
        title="Adicionar Assistente"
        showUserSearchModal={showUserSearchModal}
        setShowUserSearchModal={setShowUserSearchModal}
        endpoint='searchUser'
        selectedUser={onSelectUser}
        conditionMatcher={{
          action: 'exclude',
          key: 'id',
          value: assistants.map(assistant => assistant.id)
        }}
      />
    </>
  )
}