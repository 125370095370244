export const inputedType = (string) => {
  const emailRegex = /^([a-zA-Z0-9._%+-]+)?@([a-zA-Z0-9.-]+)?$/;
  const nameRegex = /^[a-zA-ZÀ-ÿ\s]+$/;
  const cpfRegex = /^\d{3}\.\d{3}\.\d{3}-\d{2}$|^\d{1,11}$/;

  if (emailRegex.test(string)) {
      return 'email';
  } else if (nameRegex.test(string)) {
      return 'nome';
  } else if (cpfRegex.test(string)) {
      return 'cpf_cnpj';
  } else {
      return 'unknown';
  }
}