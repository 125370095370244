import React, { useState, useEffect } from "react";
import { TitleCustom } from "../../../../components/titleCustom/TitleCustom";
import { ToggleCustom } from "../../../../components/ToggleCustom";
import { pessoa_pacienteModel, pessoaModel } from "../../../../resource/request";
import { ButtonSaveCustom } from "../../../../components/ButtonSaveCustom";
import { store } from "../../../../redux/store";
import api_app from "../../../../apis/api_app";
import withRouter from "../../../../helpers/withRouter";
import { TextAreaCustom } from "../../../../components/TextAreaCustom";
import SpinnerCustom from "../../../../components/SpinnerCustom";

interface Props {
    mostrarBotao?: boolean;
}

const DadosHistoricos: React.FC<Props & any> = (props) => {
    const [msg, setMsg] = useState<string>('');
    const [pessoa, setPessoa] = useState<pessoaModel>(store.getState().user?.user);
    const [pessoaPaciente, setPessoaPaciente] = useState<pessoa_pacienteModel>(
        store.getState().user?.user?.pessoa_paciente?.find(() => true) || {} as pessoa_pacienteModel
    );
    const [loading, setLoading] = useState<boolean>(false);
    const [isSubmitted, setIsSubmitted] = useState<boolean>(false);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
        const { name, value } = event.target;
        setPessoaPaciente((prevState) => ({
            ...prevState,
            pessoa_id: store.getState().user?.user.id,
            [name]: value,
            loading: false,
        }));
    };

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        setLoading(true);

        try {
            await api_app.atualizarPessoaPaciente(
                pessoaPaciente.id === undefined ? 0 : pessoaPaciente.id, 
                pessoaPaciente
            );
            setMsg("Seus dados foram atualizados com sucesso!");
        } catch (error) {
            console.error("Erro ao atualizar os dados:", error);
        } finally {
            setLoading(false);
        }

        props.router.updateStore();
    };

    useEffect(() => {
        if (!pessoa.cpf_cnpj) {
            setLoading(true);
        }
    }, [pessoa]);

    if (!pessoa.cpf_cnpj) {
        return (
            <div className="flex justify-center items-center h-screen">
                <SpinnerCustom />
            </div>
        );
    }

    return (
        <>
            <form onSubmit={handleSubmit}>
                <div className="flex items-center justify-between p-4 md:p-5 rounded-t dark:border-gray-600">
                    <TitleCustom name="Minha saúde" />
                </div>
                <div className="grid gap-6 mb-6 md:grid-cols-2">
                    <ToggleCustom
                        label="Fuma?"
                        value={pessoaPaciente.flg_tabagismo || 'S'}
                        name="flg_tabagismo"
                        onChange={handleChange}
                        defaultValue={pessoaPaciente.flg_tabagismo || 'N'}
                    />
                    <ToggleCustom
                        label="Bebe?"
                        value={pessoaPaciente.flg_etilismo || 'S'}
                        name="flg_etilismo"
                        onChange={handleChange}
                        defaultValue={pessoaPaciente.flg_etilismo || 'N'}
                    />
                    <ToggleCustom
                        label="Usou ou Usa Drogas?"
                        value={pessoaPaciente.flg_utilizou_drogas || 'S'}
                        name="flg_utilizou_drogas"
                        onChange={handleChange}
                        defaultValue={pessoaPaciente.flg_utilizou_drogas || 'N'}
                    />
                    <ToggleCustom
                        label="Pratica Exercícios Físicos?"
                        value={pessoaPaciente.flg_pratica_exercicio || 'S'}
                        name="flg_pratica_exercicio"
                        onChange={handleChange}
                        defaultValue={pessoaPaciente.flg_pratica_exercicio || 'N'}
                    />
                </div>
                <div className="grid gap-4 mb-4">
                    <TextAreaCustom
                        label="Você possui alguma alergia?"
                        value={pessoaPaciente.alergias || ''}
                        name="alergias"
                        onChange={handleChange}
                    />
                    <TextAreaCustom
                        label="Liste aqui as doenças e tratamentos que você tenha realizado anteriormente:"
                        value={pessoaPaciente.doencas_previas || ''}
                        name="doencas_previas"
                        onChange={handleChange}
                    />
                    <TextAreaCustom
                        label="Informe aqui se estiver com alguma doença ou passando por algum tratamento:"
                        value={pessoaPaciente.doencas_atuais || ''}
                        name="doencas_atuais"
                        onChange={handleChange}
                    />
                    <TextAreaCustom
                        label="Informe se e quais medicações de uso contínuo você faz uso:"
                        value={pessoaPaciente.medicacoes_continuas || ''}
                        name="medicacoes_continuas"
                        onChange={handleChange}
                    />
                    <TextAreaCustom
                        label="Já fez alguma cirurgia? Qual? Em que ano?"
                        value={pessoaPaciente.cirurgias || ''}
                        name="cirurgias"
                        onChange={handleChange}
                    />
                </div>
                {props.mostrarBotao !== false && (
                    <div className="flex items-center mt-6 space-x-4 rtl:space-x-reverse">
                        <ButtonSaveCustom
                            type="submit"
                            name="Salvar"
                            disabled={loading || isSubmitted}
                        />
                    </div>
                )}
                <p className="mt-2 text-sm text-green-600 dark:text-green-500">{msg}</p>
            </form>
        </>
    );
};

export default withRouter(DadosHistoricos);
