import React, { useEffect, useState } from "react";
import { Modal } from "antd";
import api_app from "../../apis/api_app";
import * as Styled from './styles'
import { inputedType } from "../../helpers/stringTypeHelper";
import SpinnerCustom from "../../components/SpinnerCustom";
import InputSearchCustom from '../../components/InputSearchCustom';
import { FaPlus } from "react-icons/fa";
import Notification from "../notification/notification";

export const UserSearchModal = ({
  title,
  showUserSearchModal,
  setShowUserSearchModal,
  endpoint,
  selectedUser,
  conditionMatcher
}) => {
  const [isLoading, setIsLoading] = useState(false)
  const [users, setUsers] = useState([]);
  const [filter, setFilter] = useState({});
  const [userInput, setUserInput] = useState('');
  const [notificationMessage, setNotificationMessage] = useState(null)

  useEffect(() => {
    setNotificationMessage(null)
  }, [userInput])

  const getAvailableUsers = (users) => {
    if (!conditionMatcher.action) return users
    const valueSet = new Set(conditionMatcher.value);
    return users.filter(user => {
      if (conditionMatcher.action === 'exclude') {
        if (Array.isArray(conditionMatcher.value)) {
          return !valueSet.has(user[conditionMatcher.key])
        }
        return user[conditionMatcher.key] !== valueSet
      } else if (conditionMatcher.action === 'include') {
        if (Array.isArray(valueSet)) {
          return valueSet.has(user[conditionMatcher.key])
        }
        return user[conditionMatcher.key] === valueSet
      } else return users
    })
  }
  
  const fetchUsers = async () => {
    setIsLoading(true)
    try {
      const response = await api_app[endpoint](filter);
      const filteredUsers = conditionMatcher ? getAvailableUsers(response?.data) : response?.data
      setUsers(filteredUsers)
      setIsLoading(false)
    } catch (error) {
      console.error(error)
    }
  }

  const handleChange = (event) => {
    const { name, value } = event.target
    switch (name) {
      case 'search':{
        setUserInput(value)
        setFilter({
          [inputedType(value)]: value
        })
        break;
      }
      default:
        break;
    }
  }

  const handleSearch = () => {
    if (userInput.length < 3) {
      setNotificationMessage({
        mode: 'warning',
        message: `Insira ao menos 3 caracteres.`
      });
    } else {
      fetchUsers()
    }
  }

  const cleanUpStates = () => {
    setUsers([])
    setUserInput("")
  }

  const handleOnAdd = (user) => {
    selectedUser(user)
    cleanUpStates()
    setShowUserSearchModal(false)
  }

  const handleOnClose = () => {
    cleanUpStates()
    setShowUserSearchModal(false)
  }

  const tableContent = () => {
    if (users?.length === 0) {
      return (
        <Styled.TableRow key={'07'}>
          <Styled.TableEmptyMessage colSpan={8}>
            Faça uma busca por nome, cpf ou email.
          </Styled.TableEmptyMessage>
        </Styled.TableRow>
      )
    } else {
      return (
        users?.map((user) => (
          <Styled.TableRow key={user.id}>
            <Styled.TableHeader>{user.nome}</Styled.TableHeader>
            <Styled.TableData>{user.cpf_cnpj}</Styled.TableData>
            <Styled.TableData>{user.email}</Styled.TableData>
            <Styled.TableData>
              <button onClick={() => handleOnAdd(user)}>
                <FaPlus />
              </button>
            </Styled.TableData>
          </Styled.TableRow>
        ))
      )
    }
  }

  return (
    <Modal
      height="80%"
      width="60%"
      title={title}
      open={showUserSearchModal}
      onCancel={handleOnClose}
      footer={null}
    >
      <Styled.SearchContainer>
        <InputSearchCustom
          name="search"
          value={userInput}
          onChange={handleChange}
        />
        <Styled.SearchButton onClick={handleSearch}>
          <Styled.IconSearch />
          Pesquisar
        </Styled.SearchButton>
      </Styled.SearchContainer>
      {notificationMessage && (
        <Notification mode={notificationMessage.mode} message={notificationMessage.message} />
      )}
      {isLoading ? (
        <Styled.SpinnerContainer>
          <SpinnerCustom />
        </Styled.SpinnerContainer>
      ) : (
        <Styled.TableContainer>
          <Styled.Table>
            <Styled.TableHead>
              <Styled.TableRow>
                <Styled.TableHeader scope="col" className="px-6 py-3">
                  Nome
                </Styled.TableHeader>
                <Styled.TableHeader scope="col" className="px-6 py-3">
                  CPF
                </Styled.TableHeader>
                <Styled.TableHeader scope="col" className="px-6 py-3">
                  E-Mail
                </Styled.TableHeader>
                <Styled.TableHeader scope="col" className="px-6 py-3">
                  Opções
                </Styled.TableHeader>
              </Styled.TableRow>
            </Styled.TableHead>
            <tbody>
              {tableContent()}
            </tbody>
          </Styled.Table>
        </Styled.TableContainer>
      )}
    </Modal>
  )
}