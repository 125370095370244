import { createContext, useEffect, useState } from "react";
import { v4 as uuidv4 } from 'uuid';
import * as Styled from './styles';
import Notification from "../components/notification/notification";

export const NotificationContext = createContext();

export const NotificationProvider = ({ children }) => {
  const [show, setShow] = useState(false);
  const [ready, setReady] = useState(false);
  const [messages, setMessages] = useState([]);
  const [actual, setActual] = useState(null);

  const messageSetter = (message, type) => {
    const id = uuidv4();
    setMessages(prevMessages => [
      ...prevMessages,
      { id, message, type }
    ]);
  };

  const calculeTransitionTime = () => {
    if (!actual || !actual.message) return 7;

    const averageCharacterWidth = 16 / 2;
    const marginsAndPaddings = 60 + 32;
    const maxCharsAmount = (window.innerWidth - marginsAndPaddings) / averageCharacterWidth;
    const clampedCharacters = Math.max(7, Math.min(actual.message.length, maxCharsAmount));
    const time = 0.2053 * Math.log(clampedCharacters) + 0.4008;
    return Number(time.toFixed(2));
  };

  const deleteMessage = () => {
    setTimeout(() => {
      setActual(null);
      setReady(false);
      setMessages(prevState => {
        const reman = prevState.filter(msg => msg.id !== messages[0].id)
        return reman
      });
    }, 1200);
  };

  const hideMessage = () => {
    setTimeout(() => {
      setShow(false);
      deleteMessage();
    }, 4000);
  };

  const changeShowMessage = () => {
    setTimeout(() => {
      setShow(true);
      setReady(true);
    }, 700);
  };

  const showMessage = () => {
    const firstMessage = messages[0]
    setActual(firstMessage);
    changeShowMessage();
    hideMessage();
  };

  useEffect(() => {
    if (messages.length > 0 && !show && !ready) {
      showMessage();
    }
  }, [messages, show, ready]);

  const providerValues = {
    messageSetter,
  };

  return (
    <NotificationContext.Provider value={providerValues}>
      {actual && (
        <Styled.NotificationContainer $transitionTime={calculeTransitionTime()} $hidden={!show}>
          <Notification message={actual.message} mode={actual.type} fixedMessage />
        </Styled.NotificationContainer>
      )}
      {children}
    </NotificationContext.Provider>
  );
};
