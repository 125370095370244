import api_app from "../../apis/api_app";
import { createUser } from "../../redux/user/slice";
import { store } from "../../redux/store";
import { useNavigate } from "react-router-dom";
import { useKeycloak } from "@react-keycloak/web";
import { useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import SpinnerCustom from "../../components/SpinnerCustom";
import CheckUserGroup from "../helpers/CheckUserGroup";
import { hasGroup } from "../../keycloak";

export function Home() {
    const { keycloak } = useKeycloak();
    let navigate = useNavigate();
    let dispach = useDispatch();


    const [post, setPost] = useState(false);
    useEffect(() => {
        const pessoa = store.getState().user.user;
        if (pessoa?.id === undefined) {
            if (!pessoa?.isLoggingOut) navigate('/registro');

        } else {
            setPost(true);

        }

    }, []);

    if (!post) {
        return <div
            className="flex justify-center items-center h-screen">
            <SpinnerCustom
            />
        </div>;
    } else {
        if (hasGroup("/profissional")) {
            navigate('/dados-profissionais/agenda');
        } else {
            navigate('/agendamento-de-consulta');
        }

    }
    return (

        <>
            <div>
                <p>Bem vindo {store.getState().user.user.nome}</p>
                <CheckUserGroup groupName="testes" />
            </div>
        </>
    );
}
