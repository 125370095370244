import { useNavigate } from "react-router-dom";
import * as Styled from './styles'
import { FaArrowLeft } from "react-icons/fa";

export const ButtonReturnCustom = (props) => {
  const navigate = useNavigate();
  const goBack = () => {
    if(props.link) {
      navigate(props.link);
    } else {
      navigate(-1);
    }
  }

  return <>
    <Styled.ReturnButtonContainer  
      onClick={goBack}
      $shouldFillSpaces={props.shouldFillSpaces}
    >
      <FaArrowLeft size={20} />
    </Styled.ReturnButtonContainer>
  </>
}
