import styled, { css } from "styled-components";

export const NotificationContainer = styled.div`
  position: fixed;
  right: 30px;
  bottom: 50px;
  width: fit-content;
  box-sizing: border-box;
  padding-left: 30px;
  z-index: 10000;

  ${({ $transitionTime }) => {
    const time = $transitionTime
    const value = `all ease-in-out ${time}s`

    return css`
      transition: ${value};
    `
  }}

  ${({ $hidden }) => {
    if ($hidden) {
      return css`
        transform: translateX(calc(100% + 30px));
      `
    }
  }}
`