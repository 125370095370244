import { Input, TextArea, TextAreaContainer } from './styles';

const ResumoDoCurriculo = () => {
  return (
    <>
      <TextAreaContainer>
        <label>
          UF do registro profissional
          <Input
            type="text"
            name="uf"
            placeholder="UF"
          />
        </label>
        <label>
          Nº Registro/Nº RQE
          <Input
            type="text"
            name="rqe"
            placeholder="Nº Especialidades/Nº RQE"
          />
        </label>
      </TextAreaContainer>
      <label>
        Liste aqui um resumo do seu currículo
        <TextArea name="resume" placeholder="Escreva um resumo do currículo" required />
      </label>
    </>
  );
};

export default ResumoDoCurriculo;