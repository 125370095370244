import { useState, useEffect, useRef, useCallback, useMemo } from 'react';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import KonvaSketchModal from '../konvaSketchModal/KonvaSketchModal'
import KonvaSketchBoard from '../konvaSketchModal/konvaSketchBoard/KonvaSketchBoard'
import KonvaButtonPlugin from './ckeditor-plugins/KonvaButtonPlugin';
import {
  CkEditorContainer,
  CkEditorInnerContainer,
  CkEditorWrapper,
  MainContainer,
  SaveButton
} from './styles';
import {
	ClassicEditor,
	AutoImage,
	Autosave,
	BlockQuote,
	Bold,
	CKBox,
	CloudServices,
	Essentials,
	Heading,
	ImageBlock,
	ImageCaption,
	ImageInline,
	ImageInsert,
	ImageInsertViaUrl,
	ImageResize,
	ImageStyle,
	ImageTextAlternative,
	ImageToolbar,
	ImageUpload,
	Indent,
	IndentBlock,
	Italic,
	Link,
	LinkImage,
	List,
	ListProperties,
	MediaEmbed,
	Paragraph,
	PictureEditing,
	Table,
	TableCaption,
	TableCellProperties,
	TableColumnResize,
	TableProperties,
	TableToolbar,
	TodoList,
  Undo,
	Underline
} from 'ckeditor5';
import 'ckeditor5/ckeditor5.css';
import 'ckeditor5-premium-features/ckeditor5-premium-features.css';
import translations from 'ckeditor5/translations/pt.js';
import premiumFeaturesTranslations from 'ckeditor5-premium-features/translations/pt.js';
import api_app from '../../apis/api_app';

export default function CKEditorComponent({ id, prontuary }) {
  const editorContainerRef = useRef(null);
  const editorRef = useRef(null);

  const [isLayoutReady, setIsLayoutReady] = useState(false);
  const [message, setMessage] = useState('');
  const [showKonva, setShowKonva] = useState(false)
  const [contentChanged, setContentChanged] = useState(true);
  const [ckLicenseKey, setCkLicenseKey] = useState({})
  const [editorData, setEditorData] = useState('')
  const [savedOnce, setSavedOnce] = useState(false)
  const [prontuaryId, setProntuaryId] = useState(prontuary?.id)

  const ckToken = async () => {
    const resp = await api_app.getCkEditorToken();
    setCkLicenseKey(resp.data.licenseKey)
    return resp.data.cloudServicesTokenUrl
  }

  useEffect(() => {
		setIsLayoutReady(true);

		return () => setIsLayoutReady(false);
	}, []);

  const handleSubmit = async (data) => {
    try {
      const dataToSend = {
        agenda_id: Number(id), 
        parecer: data,
      };

      if (prontuaryId) {
        await api_app.updateProntuario(prontuaryId, dataToSend)
      } else {
        if (savedOnce) {
          const response = await api_app.getProntuarioByAgendaId(id)
          setProntuaryId(response.data.id)
          await api_app.updateProntuario(response.data.id, dataToSend)
        } else {
          await api_app.postProntuario(dataToSend);
          setSavedOnce(true)
        }
      }

      setMessage('Prontuário salvo com sucesso!');

    } catch (error) {
      setMessage('Erro ao salvar o Prontuário. Tente novamente.');
      console.error('Erro ao salvar o Prontuario', error);
    }
  };

  const triggerFunction = (data) => {
    handleSubmit(data);
    setContentChanged(false);
  };

  const debounce = (func, delay) => {
    let timer;
    return (...args) => {
      if (timer) clearTimeout(timer);
      timer = setTimeout(() => {
        func(...args);
      }, delay);
    };
  };

  const debouncedTrigger = useCallback(debounce((data) => {
    if (contentChanged) {
      triggerFunction(data);
    }
  }, 3000), [contentChanged]);
  
  const handleEditorChange = (_, editor) => {
    const data = editor.getData();
    setEditorData(data)
    setContentChanged(true);
    debouncedTrigger(data)
  };

  const { editorConfig } = useMemo(() => (
    {
      editorConfig: {
				toolbar: {
					items: [
						'heading',
						'|',
						'bold',
						'italic',
						'underline',
						'|',
						'link',
						'insertImage',
            'konvaButton',
						'ckbox',
						'mediaEmbed',
						'insertTable',
						'blockQuote',
						'|',
						'bulletedList',
						'numberedList',
						'todoList',
						'outdent',
						'indent',
            'undo',
            'redo'
					],
					shouldNotGroupWhenFull: false
				},
        plugins: [
					AutoImage,
					Autosave,
					BlockQuote,
					Bold,
					CKBox,
					CloudServices,
					Essentials,
					Heading,
					ImageBlock,
					ImageCaption,
					ImageInline,
					ImageInsert,
					ImageInsertViaUrl,
					ImageResize,
					ImageStyle,
					ImageTextAlternative,
					ImageToolbar,
					ImageUpload,
					Indent,
					IndentBlock,
					Italic,
          KonvaButtonPlugin,
					Link,
					LinkImage,
					List,
					ListProperties,
					MediaEmbed,
					Paragraph,
					PictureEditing,
					Table,
					TableCaption,
					TableCellProperties,
					TableColumnResize,
					TableProperties,
					TableToolbar,
					TodoList,
          Undo,
					Underline
				],
				cloudServices: {
					tokenUrl: ckToken
				},
				heading: {
					options: [
						{
							model: 'paragraph',
							title: 'Paragraph',
							class: 'ck-heading_paragraph'
						},
						{
							model: 'heading1',
							view: 'h1',
							title: 'Heading 1',
							class: 'ck-heading_heading1'
						},
						{
							model: 'heading2',
							view: 'h2',
							title: 'Heading 2',
							class: 'ck-heading_heading2'
						},
						{
							model: 'heading3',
							view: 'h3',
							title: 'Heading 3',
							class: 'ck-heading_heading3'
						},
						{
							model: 'heading4',
							view: 'h4',
							title: 'Heading 4',
							class: 'ck-heading_heading4'
						},
						{
							model: 'heading5',
							view: 'h5',
							title: 'Heading 5',
							class: 'ck-heading_heading5'
						},
						{
							model: 'heading6',
							view: 'h6',
							title: 'Heading 6',
							class: 'ck-heading_heading6'
						}
					]
				},
        image: {
          toolbar: [
            'imageStyle:alignLeft',
            'imageStyle:alignCenter',
            'imageStyle:alignRight',
            '|',
            'imageTextAlternative',
						'toggleImageCaption',
						'|',
						'imageStyle:inline',
						'imageStyle:wrapText',
						'imageStyle:breakText',
						'|',
						'resizeImage'
          ],
          styles: [
            'full', 'side'
          ],
          resizeUnit: '%',
          resizeOptions: [
            {
              name: 'resizeImage:original',
              value: null,
              icon: 'original'
            },
            {
              name: 'resizeImage:50',
              value: '50',
              icon: 'medium'
            },
            {
              name: 'resizeImage:75',
              value: '75',
              icon: 'large'
            }
          ]
        },
        konvaButtonHandler: () => setShowKonva(true),
        initialData: prontuary?.parecer,
        language: 'pt',
        licenseKey: ckLicenseKey,
				link: {
					addTargetToExternalLinks: true,
					defaultProtocol: 'https://',
					decorators: {
						toggleDownloadable: {
							mode: 'manual',
							label: 'Downloadable',
							attributes: {
								download: 'file'
							}
						}
					}
				},
				list: {
					properties: {
						styles: true,
						startIndex: true,
						reversed: true
					}
				},
        placeholder: 'Escreva ou cole seu conteúdo aqui!',
        table: {
          contentToolbar: [
            'tableColumn',
            'tableRow',
            'mergeTableCells',
            'tableProperties',
            'tableCellProperties'
          ]
        },
        ckbox: {
          defaultUploadCategories: {
            Bitmaps: ['*bmp'],
            Pictures: ['*jpg', '*jpeg'],
            Scans: ['*png', '*tiff']
          },
        },
				translations: [translations, premiumFeaturesTranslations]
      }
    }
  ), [isLayoutReady]);

  const shouldRenderCkEditor = editorConfig && isLayoutReady

  const handleEditorReady = (editor) => {
    editorRef.current = editor;
    const editingView = editor.editing.view
    if (editingView) {
      editingView.change((writer) => {
        writer.setStyle(
          "height",
          "500px",
          editingView.document.getRoot()
        );
      });
    }
  };

  const createPngFromDataUri = async (dataUri) => {
    const file = await fetch(dataUri)
      .then(res => res.blob())
      .then(blob => new File([blob], 'image.png', { type: 'image/png' }));
  
    return file;
  }

  const insertImageToCKEditor = (editorInstance, imageFile) => {
    const reader = new FileReader();
  
    reader.onload = function (event) {
      const imageDataUrl = event.target.result;
      editorInstance.model.change(writer => {
        const imageElement = writer.createElement('imageBlock', {
          src: imageDataUrl,
        });
        editorInstance.model.insertContent(imageElement, editorInstance.model.document.selection);
      });
    };
  
    reader.readAsDataURL(imageFile);
  }

  const addEditedImage = (imageDataUri) => {
    createPngFromDataUri(imageDataUri).then(imageFile => {
      const editorInstance = editorRef.current;
      if (editorInstance) {
        insertImageToCKEditor(editorInstance, imageFile);
      } else {
        console.error("CKEditor instance is not available.");
      }
    });
  };

  return (
      <MainContainer>
        <CkEditorContainer
          className="editor-container editor-container_classic-editor"
          ref={editorContainerRef}
        >
          <CkEditorInnerContainer>
            <CkEditorWrapper ref={editorRef}>
              {shouldRenderCkEditor &&
                <CKEditor
                  editor={ClassicEditor}
                  config={editorConfig}
                  onReady={handleEditorReady}
                  onChange={handleEditorChange}
                />
              }
            </CkEditorWrapper>
          </CkEditorInnerContainer>
        </CkEditorContainer>
        <SaveButton
          className="text-white bg-green-500 hover:bg-green-600 focus:ring-4 border border-white font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 text-right"
          style={{ backgroundColor: '#a7eb51' }}
          onClick={() => handleSubmit(editorData)}
        >
          Salvar
        </SaveButton>
        {message && (
            <p className={`mt-2 text-sm ${message.includes('Erro') ? 'text-red-600 dark:text-red-500' : 'text-green-600 dark:text-green-500'}`}>
              {message}
            </p>
        )}
        {showKonva &&
          <KonvaSketchModal
            show={showKonva}
            onBlurClick={setShowKonva}
            onCloseClick={setShowKonva}
            $overmodal={false}
            $fixedwidth={false}
          >
            <KonvaSketchBoard onCloseButton={setShowKonva} setImageUri={addEditedImage} />
          </KonvaSketchModal>
        }
      </MainContainer>
  );
}
