import { Overlay, ModalContainer, Header, Title, CloseButton, Content, Footer } from './styles';

const Modal = ({ show, onClose, title, children, footerContent }) => {
  if (!show) return null;

  return (
    <Overlay>
      <ModalContainer>
        <Header>
          {title && <Title>{title}</Title>}
          <CloseButton onClick={onClose}>
            <svg
              className="w-5 h-5"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 14 14"
            >
              <path
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M1 1l6 6m0 0l6 6M7 7l6-6M7 7L1 13"
              />
            </svg>
          </CloseButton>
        </Header>
        <Content>{children}</Content>
        {footerContent && <Footer>{footerContent}</Footer>}
      </ModalContainer>
    </Overlay>
  );
};

export default Modal;