import { Dropdown, Input } from './styles';
import { keycloak } from '../../../keycloak';

const DadosCadastrais = ({ userType, handleUserTypeChange, isSelfRegistration }) => {
  const handleInputChange = (e) => {
    const { name, value } = e.target;

    if (name === 'phone') {
      const phoneValue = value
      .replace(/\D/g, '')
      .replace(/^(\d{2})(\d)/, '($1) $2')
      .replace(/(\d{4,5})(\d)/, '$1-$2')
      .replace(/(\d{4})\d+?$/, '$1');
      e.target.value = phoneValue;
    } else if (name === 'birthDate') {
      const birthDateValue = value
        .replace(/\D/g, '')
        .replace(/^(\d{2})(\d)/, '$1/$2')
        .replace(/(\d{2})(\d)/, '$1/$2')
        .replace(/(\d{4})\d+?$/, '$1');
      e.target.value = birthDateValue;
    }
  };

  return (
    <>
      <label>
        Tipo de Usuário
        <Dropdown value={userType} name="userType" onChange={handleUserTypeChange} required >
          <option value="">Selecione...</option>
          <option value="paciente">Paciente</option>
          <option value="profissional">Profissional</option>
        </Dropdown>
      </label>
      <label>
        Nome Completo
        <Input
          type="text"
          name="fullName"
          placeholder="Digite o nome completo"
          defaultValue={isSelfRegistration ? keycloak?.idTokenParsed?.name.toString() : ''}
          disabled={isSelfRegistration}
          required
        />
      </label>
      <label>
        CPF
        <Input
          type="text"
          name="cpf"
          maxLength="11"
          placeholder="Digite o CPF (somente números)"
          defaultValue={isSelfRegistration ? keycloak?.idTokenParsed?.preferred_username : ''}
          disabled={isSelfRegistration}
          required />
      </label>
      <label>
        Telefone
        <Input
          type="text"
          name="phone"
          onChange={handleInputChange}
          placeholder="(00) 0 000000000"
          required
        />
      </label>
      <label>
        Email
        <Input
          type="email"
          name="email"
          placeholder="Digite o email"
          defaultValue={isSelfRegistration ? keycloak?.idTokenParsed?.email : ''}
          disabled={isSelfRegistration}
          required
        />
      </label>
      <label>
        Data de Nascimento
        <Input
          type="text"
          name="birthDate"
          onChange={handleInputChange}
          placeholder="dd/mm/yyyy"
          required
        />
      </label>
    </>
  );
};

export default DadosCadastrais;