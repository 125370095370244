import styled from "styled-components";
import { FaPlus } from "react-icons/fa";

export const PatientContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: 1.5rem;
  gap: 1.5rem;
`;

const Button = styled.button`
  display: inline-flex;
  align-items: center;
  padding: 0.625rem 0.75rem;
  margin-inline-start: 0.5rem;
  font-size: 0.875rem;
  line-height: 24px;
  font-weight: 500;
  color: white;
  background-color: #3b5179;
  border-radius: 0.5rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
  height: 42px;
  white-space: nowrap;

  &:focus {
    outline: none;
    box-shadow: 0 0 0 4px rgba(59, 81, 121, 0.3);
  }

  &:hover {
    background-color: #324264;
  }
`;

export const AddButton = styled(Button)`
  margin-bottom: 0.5rem;
`;

export const IconPlus = styled(FaPlus)`
  margin-right: 4px;
`