import styled from "styled-components";

export const ReturnButtonContainer = styled.div`
  color: white;
  background-color: #3b5179;
  font-weight: 500; /* equivalent to "font-medium" */
  font-size: 0.875rem; /* equivalent to "text-sm" */
  padding: 0.625rem 1.25rem; /* equivalent to "py-2.5 px-5" */
  margin-inline-end: 0.5rem; /* equivalent to "me-2" */
  margin-bottom: 0.5rem; /* equivalent to "mb-2" */
  border-radius: 0.5rem; /* equivalent to "rounded-lg" */
  cursor: pointer;
  transition: background-color 0.3s ease;
  
  ${({ $shouldFillSpaces }) =>
    $shouldFillSpaces && `
        margin: 0 0 0 16px;
    `}

  &:hover {
    background-color: #2a3e59; /* Darker shade for hover */
  }

  &:focus {
    outline: none;
    box-shadow: 0 0 0 4px rgba(59, 81, 121, 0.3); /* equivalent to "focus:ring-4" */
  }
`;