import { Link } from "react-router-dom";
import styled, { css }  from "styled-components";

export const MenuLinkContainer = styled(Link)`
  display: flex;
  align-items: center;
  width: 100%;
  padding: 0.5rem;
  color: ${({ theme }) => theme.dark ? '#ffffff' : '#1f2937'};
  border-radius: 0.5rem;
  transition: background-color 0.75s ease;
  cursor: pointer;

  &:hover {
    background-color: ${({ theme }) => theme.dark ? '#374151' : '#f3f4f6'};
  }
`;

export const SecretariatLabel = styled.p`
  font-size: 1rem;
  color: ${({ theme }) => theme.dark ? '#ffffff' : '#1f2937'};
`;

export const ListItem = styled.li`
  list-style: none;
`;

export const Button = styled.button`
  display: flex;
  align-items: center;
  width: 100%;
  padding: 0.5rem;
  font-size: 1rem;
  text-align: left;
  color: #1f2937;
  background-color: transparent;
  border: none;
  border-radius: 0.5rem;
  transition: all 0.2s ease;
  cursor: pointer;

  &:hover {
    background-color: #f3f4f6;
  }

  &:focus {
    outline: 2px solid #3b82f6;
  }

  ${({ theme }) =>
    theme.dark &&
    css`
      color: #ffffff;
      &:hover {
        background-color: #374151;
      }
    `}
`;

export const SvgIcon = styled.svg`
  width: 1.5rem;
  height: 1.5rem;
  margin-right: 0.5rem;
  color: #1f2937;
  transition: transform 0.3s ease;

  &.rotate {
    transform: rotate(90deg);
  }

  ${({ theme }) =>
    theme.dark &&
    css`
      color: #ffffff;
    `}
`;

export const Submenu = styled.ul`
  padding-left: 2rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  display: ${({ show }) => (show ? "block" : "none")};
`;

export const StyledLink = styled(Link)`
  display: flex;
  align-items: center;
  width: 100%;
  padding: 0.5rem;
  font-size: 1rem;
  text-decoration: none;
  color: #1f2937;
  background-color: transparent;
  border-radius: 0.5rem;

  &:hover {
    background-color: #f3f4f6;
  }

  ${({ theme }) =>
    theme.dark &&
    css`
      color: #ffffff;
      &:hover {
        background-color: #374151;
      }
    `}
`;
