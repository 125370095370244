import { Checkbox } from "flowbite-react";
import { Component } from "react";
import { TitleCustom } from "../../../../../components/titleCustom/TitleCustom";
import { ButtonCreateCustom } from "../../../../../components/ButtonCreateCustom";
import { ButtonEditCustom } from "../../../../../components/ButtonEditCustom";
import { ButtonDeleteCustom } from "../../../../../components/ButtonDeleteCustom";
import ButtonSearchCuston from "../../../../../components/ButtonSearchCustom";
import { pessoaModel } from "../../../../../resource/request";
import { store } from "../../../../../redux/store";
import InputSearchCuston from "../../../../../components/InputSearchCustom";

interface State {
    pessoa: pessoaModel;
    searchTerm: string;
}
export class ListEmail extends Component<any> {

    state: State = {
        pessoa: store.getState().user?.user,
        searchTerm: "",
    };


    componentDidMount() {

    }

    handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({ searchTerm: event.target.value });
    };

    getFilteredEmails() {
        const { searchTerm, pessoa } = this.state;
        if (!searchTerm) return pessoa?.pessoa_email || [];

        return (pessoa?.pessoa_email || []).filter((email) =>
            [email.email, email.observacao]
                .map(field => field ?? '') // Garantir que campo nunca seja undefined
                .some(field => field.toLowerCase().includes(searchTerm.toLowerCase()))
        );
    }


    render() {
        const filteredEmails = this.getFilteredEmails();

        return <>
            <div className="text-lg font-semibold text-gray-900 dark:text-white">
                <TitleCustom name="Dados De E-mail" />
            </div>
            <div className="flex flex-column sm:flex-row flex-wrap space-y-4 sm:space-y-0 items-center justify-between pb-4">
                <ButtonCreateCustom link="/adicionar-email" name="Adicionar E-mail" />
                <InputSearchCuston
                    name="search"
                    value={this.state.searchTerm}
                    onChange={this.handleSearchChange}
                />
            </div>
            <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
                <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                    <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                        <tr>
                            <th scope="col" className="px-6 py-3">
                                E-mail
                            </th>
                            <th scope="col" className="px-6 py-3">
                                Descrição
                            </th>
                            <th scope="col" className="px-6 py-3">
                                Principal
                            </th>
                            <th scope="col" className="px-6 py-3">
                                Opções
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {filteredEmails.map((emails) => (
                            <tr
                            className={`bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600 ${emails.flg_visivel === 'S' ? '' : 'hidden'}`}
                            >
                                <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                    {emails.email}
                                </th>
                                <td className="px-6 py-4">
                                    {emails.observacao}
                                </td>
                                <td className="px-6 py-4">
                                    {emails.flg_principal == "S" ? 'Sim' : 'Não'}
                                </td>
                                <td className="px-6 py-4">
                                    <div className="flex items-center">
                                        <ButtonEditCustom link={"/alterar-email/" + emails.id} />
                                        {emails.flg_principal !== "S" && (
                                            <>

                                                <ButtonDeleteCustom link={"/pessoa-email/" + emails.id} />
                                            </>
                                        )}

                                    </div>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </>
    }
}