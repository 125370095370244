import { hasGroup } from "../keycloak";

export const canAccessAgenda = (agendaStatus) => {
  return (agendaStatus === "Confirmada" ||
    agendaStatus === "Em andamento") &&
    !hasGroup("/secretariado");
}

export const canDeleteAgenda = (agendaStatus, agendaStartTime) => {
  const now = new Date();
  const agendaDate = new Date(agendaStartTime)
  return agendaStatus !== "Finalizada" &&
  agendaStatus !== "Em andamento" &&
  agendaDate > now;
}

export const canAccessPatientInfo = (agendaStatus) => {
  if (hasGroup("/secretariado")) return false;
  switch (agendaStatus) {
    case "Confirmada":
    case "Em andamento":
    case "Pendente":
    case "Finalizada":
    case "Cancelada":
      return true;
    default:
      return false;
  }
}
