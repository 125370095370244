
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import 'flowbite';
import './index.css';
import { ReactKeycloakProvider } from '@react-keycloak/web';
import { Provider } from 'react-redux';
import { store } from './redux/store';
import { keycloak } from "./keycloak";
import App from './App';
import { NotificationProvider } from './contexts/noitificationProvider';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(

  <ReactKeycloakProvider
    authClient={keycloak}
    initOptions={{ onLoad: 'login-required' }}
  >
    <Provider store={store}>
      <NotificationProvider>
        <App />
      </NotificationProvider>
    </Provider>
  </ReactKeycloakProvider>

);

reportWebVitals();
