import { Checkbox } from "flowbite-react";
import { Component } from "react";
import { TitleCustom } from "../../../../../components/titleCustom/TitleCustom";
import { ButtonCreateCustom } from "../../../../../components/ButtonCreateCustom";
import { ButtonEditCustom } from "../../../../../components/ButtonEditCustom";
import { ButtonDeleteCustom } from "../../../../../components/ButtonDeleteCustom";
import ButtonSearchCuston from "../../../../../components/ButtonSearchCustom";

export class PlanoFinanceiro extends Component {
    render() {
        return <>
            <div className="text-lg font-semibold text-gray-900 dark:text-white">
                <TitleCustom name="Planos Individuais SEC24" />
            </div>
            <div className="flex flex-column sm:flex-row flex-wrap space-y-4 sm:space-y-0 items-center justify-between pb-4">
                <ButtonCreateCustom link="/adicionar-plano-individual" name="Adicionar Plano Individual" />
                <ButtonSearchCuston />
            </div>
            <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
                <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                    <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                        <tr>
                            <th scope="col" className="p-4">
                                <Checkbox />
                            </th>
                            <th scope="col" className="px-6 py-3">
                                Tipo de Plano
                            </th>
                            <th scope="col" className="px-6 py-3">
                                Tipo de Assinatura
                            </th>
                            <th scope="col" className="px-6 py-3">
                                Status Assinatura
                            </th>
                            <th scope="col" className="px-6 py-3">
                                Próximo Vencimento
                            </th>
                            <th scope="col" className="px-6 py-3">
                                Valor da Recorrência
                            </th>
                            <th scope="col" className="px-6 py-3">
                                Tipo de Recorrência
                            </th>
                            <th scope="col" className="px-6 py-3">
                                Data Início Vigência
                            </th>
                            <th scope="col" className="px-6 py-3">
                                Data Fim Vigência
                            </th>
                            <th scope="col" className="px-6 py-3">
                                Carência (Dias)
                            </th>
                            <th scope="col" className="px-6 py-3">
                                Consulta Presencial
                            </th>
                            <th scope="col" className="px-6 py-3">
                                Consulta On-line
                            </th>
                            <th scope="col" className="px-6 py-3">
                                Pronto Atendimento
                            </th>
                            <th scope="col" className="px-6 py-3">
                                Opções
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                            <td className="w-4 p-4">
                                <Checkbox />
                            </td>
                            <td scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                Individual
                            </td>
                            <td className="px-6 py-4">
                                Individual-Bronze
                            </td>
                            <td className="px-6 py-4">
                                Ativo
                            </td>
                            <td className="px-6 py-4">
                                21/05/2027
                            </td>
                            <td className="px-6 py-4">
                                R$ 49,90
                            </td>
                            <td className="px-6 py-4">
                                Mensal
                            </td>
                            <td className="px-6 py-4">
                                21/01/2024
                            </td>
                            <td className="px-6 py-4">
                                21/01/2025
                            </td>
                            <td className="px-6 py-4">
                                0
                            </td>
                            <td className="px-6 py-4">
                                Sim
                            </td>
                            <td className="px-6 py-4">
                                Sim
                            </td>
                            <td className="px-6 py-4">
                                Sim
                            </td>
                            <td className="px-6 py-4">
                                <div className="flex items-center">
                                    <ButtonEditCustom link="/alterar-plano-individual" />
                                    <ButtonDeleteCustom link="/" />
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </>
    }
}