import { useSearchParams } from "react-router-dom";
import Modal from "../../../components/modal/modal";
import RegisterNewUser from "../../../components/registerNewUser/registerNewUser";
import Notification from "../../../components/notification/notification";
import { useState } from "react";

const RegistrarUsuario = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [notificationMessage, setNotificationMessage] = useState(null)
  const showModal = searchParams.get("modal") === "open";

  const handleCloseModal = () => {
    searchParams.delete("modal");
    setSearchParams(searchParams);
  };

  return (
    <>
      {notificationMessage && (
        <Notification mode={notificationMessage.mode} message={notificationMessage.message} />
      )}
      {showModal && (
        <Modal
          show={true}
          onClose={handleCloseModal}
          children={<RegisterNewUser
            searchParams={searchParams}
            setSearchParams={setSearchParams}
            setNotificationMessage={setNotificationMessage}
          />}
        />
      )}
    </>
  );
};

export default RegistrarUsuario;
