import styled, { css } from 'styled-components';

export const NotificationWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 10px 16px;
  border-radius: 8px;
  background-color: ${({ mode }) =>
    mode === 'warning' ? 'rgba(251, 222, 192)'
    : mode === 'error' ? 'rgba(255, 235, 236)'
    : 'rgba(230, 249, 239)'};
  color: #111827;
  font-size: 16px;
  margin: ${({ $fixedMessage }) => $fixedMessage ? '0' : '16px 0' };

  ${({ $fixedMessage, mode }) => {
    if ($fixedMessage) {
      const color =
      mode === 'warning' ? 'rgba(251, 190, 128, 1)'
      : mode === 'error' ? 'rgba(255, 200, 200, 1)'
      : 'rgba(180, 230, 200, 1)';

      const value = `solid 1px ${color}`

      return css`
        border: ${value} ;
      `
    }
  }}
`;

export const IconWrapper = styled.div`
  margin-right: 10px;
  font-size: 20px;
  color: ${({ mode }) =>
    mode === 'warning' ? '#f9962f'
    : mode === 'error' ? '#c53434'
    : '#207c50'};
`;

export const Message = styled.div`
  flex: 1;
`;