import React, { useState } from "react";
import { TitleCustom } from "../../../../../components/titleCustom/TitleCustom";
import { ButtonCreateCustom } from "../../../../../components/ButtonCreateCustom";
import { ButtonEditCustom } from "../../../../../components/ButtonEditCustom";
import { ButtonDeleteCustom } from "../../../../../components/ButtonDeleteCustom";
import ButtonSearchCuston from "../../../../../components/ButtonSearchCustom";
import { pessoaModel } from "../../../../../resource/request";
import { store } from "../../../../../redux/store";
import { formatMoneyToBRL } from "../../../../../helpers/moneyHelpers";

const Especialidades: React.FC<any> = () => {
    const [pessoa] = useState<pessoaModel>(store.getState().user?.user);
    const [msg, setMsg] = useState<string>("");

    return (
        <>
            <div className="text-lg font-semibold text-gray-900 dark:text-white">
                <TitleCustom name="Especialidades" />
            </div>
            <div className="flex flex-column sm:flex-row flex-wrap space-y-4 sm:space-y-0 items-center justify-between pb-4">
                <ButtonCreateCustom link="/adicionar-especialidade" name="Adicionar Especialidades" />
                <ButtonSearchCuston />
            </div>
            <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
                <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                    <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                        <tr>
                            <th scope="col" className="px-6 py-3">Especialidades</th>
                            <th scope="col" className="px-6 py-3">Nº Especialidades / Nº RQE</th>
                            <th scope="col" className="px-6 py-3">Valor</th>
                            <th scope="col" className="px-6 py-3">Opções</th>
                        </tr>
                    </thead>
                    <tbody>
                        {pessoa?.pessoa_especialidade?.map((especi) => (
                            <tr
                                key={especi.id}
                                className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600"
                            >
                                <th
                                    scope="row"
                                    className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                                >
                                    {especi?.especialidade?.especialidade}
                                </th>
                                <td className="px-6 py-4">{especi.num_especialidade_rqe}</td>
                                <td className="px-6 py-4">{formatMoneyToBRL(especi.vlr_atendimento)}</td>
                                <td className="px-6 py-3">
                                    <div className="flex items-center">
                                        <ButtonEditCustom link={`/alterar-especialidade/${especi.id}`} />
                                        <ButtonDeleteCustom link={`/pessoa-especialidades/${especi.id}`} />
                                    </div>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </>
    );
};

export default Especialidades;
