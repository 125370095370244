import { Tabs } from "flowbite-react";
import React, { useEffect, useState } from "react";
import { store } from "../../redux/store";
import { useParams } from "react-router-dom";
import api_app from "../../apis/api_app";
import { TitleCustom } from "../../components/titleCustom/TitleCustom";
import InputDisabled from "../../components/InputDisabled";
import { TextAreaCustom } from "../../components/TextAreaCustom";
import SpinnerCustom from "../../components/SpinnerCustom";
import { Accordion, Timeline } from "flowbite-react";
import { ButtonReturnCustom } from "../../components/ButtonReturnCustom/ButtonReturnCustom";
import AddressesTable from "../../components/AddressesTable";
import { extractTimeFromDateString, parseDateToDMYFormat } from "../../helpers/date_helpers";
import { ProntuarioBox } from "./styles";
import ProtectedImg from "../../components/ProtectedImg";

export function DadosDoPaciente() {
    const [isPatient, setIsPatient] = useState(false);
    const [image, setImage] = useState(null);
    const [pessoa, setPessoa] = useState({});
    let params = useParams();
    const id = params.id;
    const loggedUserId = store.getState().user?.user?.id;


    useEffect(() => {
        api_app.getPessoa(id).then((resp) => {
            const pessoaData = resp.data
            setPessoa(pessoaData)
            const documentos = pessoaData.pessoa_documento.filter((doc) => (
                doc.tipo_documento_id === 7
            ))
            if (documentos?.length > 0) {
                const primeiraImagem = documentos[0];
                setImage(primeiraImagem);
            } else {
                setImage("/FotoUser.jpg");
            }
            if (loggedUserId === pessoaData.id) setIsPatient(true);
        })
    }, [id]);

    if (pessoa.id === undefined || pessoa.id === 0) {
        return <>
            <div className="flex justify-center items-center h-screen bg-gray-100 dark:bg-gray-900">
                <SpinnerCustom />
            </div>
        </>
    }

    return (
        <>
            {!isPatient && (
                <div className="overflow-x-auto">
                    <div className="flex items-center justify-between rounded-t mb-2 dark:border-gray-600">
                        <TitleCustom
                            name="Dados do Paciente"
                        />
                        <ButtonReturnCustom />
                    </div>
                    <Tabs>
                        <Tabs.Item title="Informações Gerais">
                            <div>
                                <form>
                                    <div className="flex justify-between items-center text-lg">
                                        <TitleCustom name="Dados Pessoais" />
                                    </div>
                                    <div className="flex flex-row justify-start items-center mb-6">
                                        <div className="flex-shrink-0 w-1/2 items-center">
                                            <div className="flex-shrink-0 ml-28 w-1/2 ">
                                                {image && image.id && (
                                                    <ProtectedImg
                                                        alt="Document"
                                                        src={`${process.env.REACT_APP_BASE_URL}/pessoa-documento/${image.id}`}
                                                        className="max-w-full max-h-full"
                                                    />
                                                )}
                                            </div>
                                        </div>
                                        <div className="flex-1 ml-1 flex flex-col items-center mt-4">
                                            <div className="mb-2 w-full">
                                                <InputDisabled
                                                    label="Nome Completo:"
                                                    value={pessoa.nome || ''}
                                                    type="text"
                                                    name="nome"
                                                    placeholder="Nome Completo"
                                                />
                                            </div>
                                            <div className="mb-2 w-full">
                                                <InputDisabled
                                                    label="Nome Social:"
                                                    value={pessoa.pessoa_fisica.nome_social || ''}
                                                    type="text"
                                                    name="nome_social"
                                                    placeholder="Nome Social"
                                                />
                                            </div>
                                            <div className="mb-2 w-full">
                                                <InputDisabled
                                                    label="CPF:"
                                                    value={pessoa.cpf_cnpj || ''}
                                                    type="text"
                                                    name="cpf_cnpj"
                                                    placeholder="000.000.000-00"
                                                />
                                            </div>
                                            <div className="mb-2 w-full">
                                                <InputDisabled
                                                    label="Data de Nascimento:"
                                                    value={parseDateToDMYFormat(pessoa.dat_nascimento_fundacao)}
                                                    type="text"
                                                    name="data_nascimento"
                                                    placeholder="00/00/0000"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="grid gap-6 mb-6 md:grid-cols-2">
                                        <InputDisabled
                                            label="Telefone:"
                                            value={pessoa.pessoa_telefone?.find((tel) => tel.flg_principal === 'S')?.telefone || ''}
                                            type="text"
                                            name="telefone"
                                            placeholder="Telefone"
                                        />
                                        <InputDisabled
                                            label="E-mail:"
                                            value={pessoa.pessoa_email?.find((x) => x.flg_principal === 'S')?.email || ''}
                                            type="text"
                                            name="email"
                                            placeholder="E-mail"
                                        />
                                        <InputDisabled
                                            label="Estado Civil:"
                                            value={pessoa.pessoa_fisica.estado_civil || ''}
                                            type="text"
                                            name="estado_civil"
                                            placeholder="Estado Civil"
                                        />
                                        <div className="mb-2 w-full">
                                            <InputDisabled
                                                label="RG/RNE:"
                                                value={pessoa.pessoa_fisica.rg || ''}
                                                type="text"
                                                name="rg"
                                                placeholder="RG/RNE"
                                            />
                                        </div>
                                        <InputDisabled
                                            label="Órgão Expedidor/UF:"
                                            value={pessoa.pessoa_fisica.rg_orgao_expedidor || ''}
                                            type="text"
                                            name="rg_orgao_expedidor"
                                            placeholder="Órgão Expedidor / UF"
                                        />
                                        <InputDisabled
                                            label="Nº Do Cartão Do SUS:"
                                            value={pessoa.pessoa_fisica.cartao_sus || ''}
                                            type="text"
                                            name="cartao_sus"
                                            placeholder="Nº Do Cartão Do SUS"
                                        />
                                        <InputDisabled
                                            label="Nome Da Mãe:"
                                            value={pessoa.pessoa_fisica.nome_mae || ''}
                                            type="text"
                                            name="nome_mae"
                                            placeholder="Nome Da Mãe"
                                        />
                                        <InputDisabled
                                            label="Nome Do Pai:"
                                            value={pessoa.pessoa_fisica.nome_pai || ''}
                                            type="text"
                                            name="nome_pai"
                                            placeholder="Nome Do Pai"
                                        />
                                        <InputDisabled
                                            label="Etnia:"
                                            value={pessoa.pessoa_fisica.raca_etnia || ''}
                                            name="raca_etnia"
                                            type="text"
                                            placeholder="Etnia"
                                        />
                                        <InputDisabled
                                            label="Gênero:"
                                            value={pessoa.pessoa_fisica.tipo_genero || ''}
                                            name="tipo_genero"
                                            type="text"
                                            placeholder="Gênero"
                                        />
                                        <InputDisabled
                                            label="Pronome Gênero:"
                                            value={pessoa.pessoa_fisica.tipo_genero_pronome || ''}
                                            name="tipo_genero_pronome"
                                            type="text"
                                            placeholder="Pronome Gênero"
                                        />
                                        <InputDisabled
                                            label="Identidade De Gênero:"
                                            value={pessoa.pessoa_fisica.tipo_identidade_genero || ''}
                                            name="tipo_identidade_genero"
                                            type="text"
                                            placeholder="Identidade De Gênero"
                                        />
                                        <InputDisabled
                                            label="Profissão:"
                                            value={pessoa.pessoa_fisica.profissao || ''}
                                            type="text"
                                            name="profissao"
                                            placeholder="Profissão"
                                        />
                                        <InputDisabled
                                            label="Pis/Pasep:"
                                            value={pessoa.pessoa_fisica.pis_pasep || ''}
                                            type="text"
                                            name="pis_pasep"
                                            placeholder="Pis/Pasep"
                                        />                                    
                                    </div>
                                </form>
                                <div>
                                    <label className="block mb-2 text-sm font-medium text-gray-800 dark:text-gray-200">
                                        Endereços:
                                    </label>
                                    <AddressesTable addressesList={pessoa?.pessoa_endereco} showOptions={false}/>
                                </div>
                            </div>
                        </Tabs.Item>

                        <Tabs.Item title="Histórico Médico">
                            <div>
                                <form>
                                    <div className="flex justify-between items-center text-lg mb-2">
                                        <TitleCustom
                                            name="Minha saúde"
                                        />
                                    </div>
                                    <div className="grid gap-6 mb-6 md:grid-cols-2">
                                        <h1>Fuma: {pessoa.pessoa_paciente?.flg_tabagismo == 'S' ? 'Sim' : 'Não'}</h1>
                                        <h1>Bebe: {pessoa.pessoa_paciente?.flg_etilismo == 'S' ? 'Sim' : 'Não'}</h1>
                                        <h1>Usou ou Usa Drogas: {pessoa.pessoa_paciente?.flg_utilizou_drogas == 'S' ? 'Sim' : 'Não'}</h1>
                                        <h1>Pratica Exercícios Físicos: {pessoa.pessoa_paciente?.flg_pratica_exercicio == 'S' ? 'Sim' : 'Não'}</h1>
                                    </div>
                                    <div className="grid gap-4 mb-4">
                                        <TextAreaCustom
                                            label="Você possui alguma alergia?"
                                            value={pessoa.pessoa_paciente?.alergias || ''}
                                            name='alergias'
                                            disabled
                                        />
                                        <TextAreaCustom
                                            label="Liste aqui as doenças e tratamentos que você tenha realizado anteriormente:"
                                            value={pessoa.pessoa_paciente?.doencas_previas || ''}
                                            name='doencas_previas'
                                            disabled
                                        />
                                        <TextAreaCustom
                                            label="Informe aqui se estiver com alguma doença ou passando por algum tratamento:"
                                            value={pessoa.pessoa_paciente?.doencas_atuais || ''}
                                            name='doencas_atuais'
                                            disabled
                                        />
                                        <TextAreaCustom
                                            label="Informe se e quais medicações de uso contínuo você faz uso:"
                                            value={pessoa.pessoa_paciente?.medicacoes_continuas || ''}
                                            name='medicacoes_continuas'
                                            disabled
                                        />
                                        <TextAreaCustom
                                            label="Já fez alguma cirurgia? Qual? Em que ano?"
                                            value={pessoa.pessoa_paciente?.cirurgias || ''}
                                            name='cirurgias'
                                            disabled
                                        />
                                    </div>
                                </form>
                            </div>
                        </Tabs.Item>

                        <Tabs.Item title="Histórico de Prontuário">
                            <Accordion>
                                {pessoa.agendas?.filter((x) => x.status_agenda_id === 5)
                                    .sort((a, b) => new Date(a.data) < new Date(b.data) ? 1 : -1)
                                    .map((agenda, index) => (
                                    <Accordion.Panel key={index}>
                                        <Accordion.Title className="bg-gray-200 p-4 rounded-lg shadow-md">
                                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                <p style={{ marginRight: '10px' }}>
                                                    {agenda.data
                                                        ? `${parseDateToDMYFormat(new Date(agenda.data))} - ${extractTimeFromDateString(new Date(agenda.data))} - `
                                                        : '- - - '
                                                    }
                                                </p>
                                                <p>
                                                    Médico: {`${agenda.profissional?.nome || '-'} - `}
                                                    {agenda.profissional?.especialidade || '-'}
                                                </p>
                                            </div>
                                        </Accordion.Title>
                                        <Accordion.Content className="p-4">
                                            <Timeline className="mt-4">
                                                <Timeline.Item>
                                                    <Timeline.Content>
                                                        <Timeline.Body>
                                                            <div className="ml-2">
                                                                <ProntuarioBox
                                                                    dangerouslySetInnerHTML={{
                                                                        __html: agenda.prontuario?.parecer || '',
                                                                    }}
                                                                />
                                                            </div>
                                                        </Timeline.Body>
                                                    </Timeline.Content>
                                                </Timeline.Item>
                                            </Timeline>
                                        </Accordion.Content>
                                    </Accordion.Panel>
                                )) ?? []}
                            </Accordion>
                        </Tabs.Item>
                    </Tabs>

                </div>
            )}
        </>
    );
}