import axios from "axios";
import { getIdToken } from "../keycloak";
const REACT_APP_BASE_URL = process.env.REACT_APP_BASE_URL;
//const REACT_APP_BASE_URL = "https://app.sec24.com.br/server";


const apis = axios.create({
  baseURL: REACT_APP_BASE_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

apis.interceptors.request.use(async (config) => {
  config.headers.Authorization = `Bearer ${getIdToken()}`;
  return config;
});

export default apis;

