import styled from 'styled-components';

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 0.375rem;
  margin-bottom: 1.5rem;
  border-color: ${({ theme }) => theme.dark ? '#4b5563' : 'transparent'};
`;

export const HeaderButtonsContainer = styled.div`
  display: flex;
  align-items: center;
`

export const CreateAppointmentButton = styled.button`
  display: inline-flex;
  align-items: center;
  padding: 0.625rem 0.75rem;
  margin-inline-start: 0.5rem;
  font-size: 0.875rem;
  font-weight: 500;
  color: white;
  background-color: #3b5179;
  border-radius: 0.5rem;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:focus {
    outline: none;
    box-shadow: 0 0 0 4px rgba(59, 81, 121, 0.3);
  }

  &:hover {
    background-color: #324264;
  }
`;

export const ProntuarioBox = styled.div`
    ul,
    ol {
      list-style-position: outside;
      padding-left: 30px;
      margin-left: 0;
    }

    ul {
        list-style: circle;
    }

    ol {
        list-style: decimal;
    }

    li {
      padding-left: 10px;
    }

    blockquote {
      margin-left: 0;
      padding-left: 30px;
    }
`

export const FullCalendarContainer = styled.div`
  && {
    position: relative;

    .fc-event-content {
      width: 100%;
    }

    .fc-event-main {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 95%;
    }
  }
`

export const LoadingContainer = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f7fafc;
  width: 100%;
  height: 100%;
  background-color: rgba(247, 250, 252, 0.8);
  z-index: 10;
`
