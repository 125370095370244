import { FaExclamationTriangle, FaExclamationCircle, FaCheckCircle } from 'react-icons/fa';
import { NotificationWrapper, IconWrapper, Message } from './styles';

const Notification = ({ mode, message, fixedMessage=false }) => {
  const getIcon = () => {
    switch (mode) {
      case 'warning':
        return <FaExclamationTriangle />;
      case 'error':
        return <FaExclamationCircle />;
      case 'success':
        return <FaCheckCircle />;
      default:
        return null;
    }
  };

  return (
    <NotificationWrapper mode={mode} $fixedMessage={fixedMessage}>
      <IconWrapper mode={mode}>
        {getIcon()}
      </IconWrapper>
      <Message>{message}</Message>
    </NotificationWrapper>
  );
};

export default Notification;
