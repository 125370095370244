import React, { useEffect, useState } from 'react';
import * as Styled from './styles'
import { Modal } from 'antd';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import listPlugin from '@fullcalendar/list';
import interactionPlugin from '@fullcalendar/interaction';
import multiMonthPlugin from '@fullcalendar/multimonth';
import ptLocale from '@fullcalendar/core/locales/pt-br';
import { TitleCustom } from '../../components/titleCustom/TitleCustom';
import { ButtonDeleteCustom } from '../../components/ButtonDeleteCustom';
import { ButtonQueryCustom } from '../../components/ButtonQueryCustom';
import { CheckBoxAgendaCustom } from '../../components/CheckBoxAgendaCustom';
import api_app from '../../apis/api_app';
import { store } from '../../redux/store';
import { Link, useLocation, useParams } from 'react-router-dom';
import { ButtonDeleteAgendaCustom } from '../../components/ButtonDeleteAgendaCustom';
import {
  increaseTimeOnDate,
  joinDateTime,
  parseDateToDMYFormat,
  parseDateStringToDate,
  validateDateFormat,
  validateDMYFormat,
  increaseTimeOnTimeOnly,
  validateTimeFormat,
  validateEndTime,
  isValid24HourTime,
  extractTimeFromDateString,
  formatarDataEIdade,
  daysAppointmentAmount
} from '../../helpers/date_helpers';
import InputCustom from '../../components/InputCustom';
import { getWeekArray } from '../../helpers/weeks_helpers';
import Notification from '../../components/notification/notification';
import { ButtonReturnCustom } from '../../components/ButtonReturnCustom/ButtonReturnCustom';
import { hasGroup } from '../../keycloak';
import SpinnerCustom from '../../components/SpinnerCustom';
import {
  canAccessAgenda,
  canAccessPatientInfo,
  canDeleteAgenda
} from '../../helpers/permissionHelper';

const AgendaProfissional = () => {
  const location = useLocation()
  const [adicionarModalVisible, setAdicionarModalVisible] = useState(false);
  const [eventos, setEventos] = useState(null);
  const [selectedDate, setSelectedDate] = useState(null);
  const [consultModalVisible, setConsultModalVisible] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [startTime, setStartTime] = useState('');
  const [endTime, setEndTime] = useState('');
  const [duration, setDuration] = useState('');
  const { id } = useParams();
  const [modalVisible, setModalVisible] = useState(false);
  const [eventDetails, setEventDetails] = useState(null);
  const [showMessage, setShowMessage] = useState('')
  const [isLoading, setIsLoading] = useState(false);
  const userId = store.getState().user.user.id
  const queryParams = new URLSearchParams(location.search);
  const urlQueries = Object.fromEntries(queryParams.entries());
  const profissionalId = urlQueries.profissional_id || userId
  const profissionalNome = urlQueries.profissional_nome

  useEffect(() => {
    if (adicionarModalVisible) {
      const now = new Date();
      const timeNow = now.toTimeString().split(' ')[0].slice(0, 5);
      const newEndTime = increaseTimeOnTimeOnly(timeNow, '00:30');

      setStartDate(parseDateToDMYFormat(now));
      setEndDate(parseDateToDMYFormat(now));
      setStartTime(timeNow);
      setEndTime(newEndTime);
      setDuration('00:30');
    }
  }, [adicionarModalVisible]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const responseAgenda = hasGroup("/secretariado")
          ? await api_app.getAgendaProfissionalById(profissionalId)
          : await api_app.getAgendaProfissional();
        const newEventos = responseAgenda.data.map((ag) => {
          const start = new Date(ag.data)
          const end = increaseTimeOnDate(start, '00:01');

          const agendaTitle =
            ag?.status_agenda?.status_agenda === 'Livre'
            ? ag?.status_agenda?.status_agenda
            : ag.pessoa_agenda_paciente_idTopessoa?.nome
          const genero =
            ag.pessoa_agenda_paciente_idTopessoa?.pessoa_fisica &&
            ag.pessoa_agenda_paciente_idTopessoa.pessoa_fisica[0]?.tipo_genero?.tipo_genero
          const profissao =
            ag.pessoa_agenda_paciente_idTopessoa?.pessoa_fisica &&
            ag.pessoa_agenda_paciente_idTopessoa.pessoa_fisica[0]?.profissao
          const telefone =
            ag.pessoa_agenda_paciente_idTopessoa?.pessoa_fisica &&
            ag.pessoa_agenda_paciente_idTopessoa?.pessoa_telefone[0]?.telefone
          const email =
            ag.pessoa_agenda_paciente_idTopessoa?.pessoa_fisica &&
            ag.pessoa_agenda_paciente_idTopessoa?.pessoa_email[0]?.email

          return {
            title: ` - ${agendaTitle}`,
            start: start.toISOString(),
            end: end.toISOString(),
            id: ag.id,
            tipo: ag?.tipo_agenda?.tipo_agenda || 'Atendimento',
            paciente: ag.pessoa_agenda_paciente_idTopessoa?.nome || '',
            dta_nascimento: formatarDataEIdade(ag.pessoa_agenda_paciente_idTopessoa?.dat_nascimento_fundacao) || '',
            cpf_cnpj: ag.pessoa_agenda_paciente_idTopessoa?.cpf_cnpj || '',
            genero: genero || '',
            profissao: profissao || '',
            telefone: telefone || '',
            email: email || '',
            status: ag?.status_agenda?.status_agenda,
            paciente_id: ag.pessoa_agenda_paciente_idTopessoa?.id,
          };
        });

        setEventos(newEventos);
      } catch (error) {
        console.error('Erro ao buscar agenda:', error);
      }
    };

    fetchData();
  }, [id]);

  const plugins = [dayGridPlugin, timeGridPlugin, listPlugin, interactionPlugin, multiMonthPlugin];

  const closeCreateAppointmentModal = () => {
    setAdicionarModalVisible(false);
    setShowMessage('');
  }

  const handleSalvarEvento = async (e) => {
    e.preventDefault();
    const formData = new FormData(e.currentTarget);
    const startDate = formData.get('start');
    const endDate = formData.get('end');
    const startTime = formData.get('startTime');
    const endTime = formData.get('endTime');
    const duration = formData.get('duration');
    const week = getWeekArray(formData);

    if(validateEndTime(startTime, endTime, duration)) {
      setShowMessage('Favor verificar hora final para ser de acordo com a duração.')
      return;
    }

    const newEventos = createEvents(startDate, endDate, startTime, endTime, duration, week);
    closeCreateAppointmentModal();

    const eventosToPush = newEventos.map(async (appointment) => {
      const result = await api_app.postAgendas({
        data: appointment.data,
        profissional_id: Number(appointment.profissional_id),
        status_agenda_id: 1,
        dat_criado_em: new Date(),
        criada_por: userId,
      })
        .then((resp) => {
          return {
            ...appointment,
            id: resp.data.id,
            title: ' - Livre',
            status: ' - Livre',
            paciente: ''
          }
        })
        .catch((error) => {
          console.error('Erro ao buscar agendas:', error);
        });
      return result
    });

    const mappedEvents = await Promise.all(eventosToPush)

    setEventos([...eventos, ...mappedEvents])

  };

  const createEvents = (
    startDate,
    endDate,
    startTime,
    endTime,
    duration,
    week
  ) => {
    const newEvents = [];
    let currentDate = parseDateStringToDate(startDate);
    const endAppointmentDate = parseDateStringToDate(endDate);

    const createEvent = (end, data) => {
      return {
        start: data.toISOString(),
        end: end.toISOString(),
        duration: duration,
        data: data,
        horario: data.toISOString(),
        profissional_id: profissionalId
      };
    };

    while (currentDate <= endAppointmentDate) {
      const dayOfWeek = currentDate.getDay();

      if (week[dayOfWeek]) {
        let startTimeForActualAppointment = joinDateTime(currentDate, startTime);
        const endTimeAppointments = joinDateTime(currentDate, endTime);

        while (startTimeForActualAppointment < endTimeAppointments) {
          const endTimeForActualAppointment = increaseTimeOnDate(startTimeForActualAppointment, duration);
          const isActualEndTimeGraterThanLastEndTime = endTimeForActualAppointment > endTimeAppointments;

          if (isActualEndTimeGraterThanLastEndTime) break;

          const data = joinDateTime(currentDate, startTimeForActualAppointment)
          const appointment = createEvent(endTimeForActualAppointment, data)
          newEvents.push(appointment);

          startTimeForActualAppointment = new Date(endTimeForActualAppointment.getTime() + 1000);
        }
      }
      currentDate.setDate(currentDate.getDate() + 1);
    }
    return newEvents;
  };

  const handleDateSelect = (selectInfo) => {
    setSelectedDate(selectInfo.start);
    const eventsOnSelectedDate =
      eventos
        .filter((evento) =>
          new Date(evento.start).getDate() === selectInfo.start.getDate() &&
          new Date(evento.start).getMonth() === selectInfo.start.getMonth() &&
          new Date(evento.start).getFullYear() === selectInfo.start.getFullYear()
        )
        .sort((a, b) => new Date(a.start) > new Date(b.start) ? 1 : -1);
    setSelectedEvent(eventsOnSelectedDate);
    setConsultModalVisible(true);
  };

  const customEventContent = (eventInfo) => {
    return (
      <div className="fc-event-content flex items-center justify-between">
        <div className="fc-event-main">
          <span>{eventInfo.timeText}</span>
          <span>{eventInfo.event.title}</span>
        </div>
      </div>
    );
  };

  const handleEventClick = (info) => {
    setEventDetails({
      id: info.event.id,
      status: info.event.extendedProps.status,
      start: info.event.start,
      paciente: info.event.extendedProps.paciente,
      pureStatus: info.event.extendedProps.status,
      telefone: info.event.extendedProps.telefone,
      email: info.event.extendedProps.email,
      cpf_cnpj: info.event.extendedProps.cpf_cnpj,
      dta_nascimento: info.event.extendedProps.dta_nascimento,
      profissao: info.event.extendedProps.profissao,
      genero: info.event.extendedProps.genero,
      pacienteId: info.event.extendedProps.paciente_id
    });
    setModalVisible(true);
  };

  const navigateToAtendimentoConsulta = () => {
    window.location.href = `/dados-profissionais/agenda/atendimento-consulta/${eventDetails.id}`;
  };

  const deleteAppointment = async (id) => {
    setModalVisible(false)
    const appointments = eventos.filter(appointment => !(Number(appointment.id) === Number(id)))
    setEventos(appointments)
  }

  const handleOnChange = (e) => {
    const {name, value} = e.target;
    switch (name) {
      case 'start': {
        const now = new Date();
        const nowAsDMYFormat = parseDateToDMYFormat(now);
        setStartDate(value);
        if (validateDateFormat(value)) {
          const startInputAsDate = parseDateStringToDate(value);
          const endInputAsDate = parseDateStringToDate(endDate)
          if (startInputAsDate < now) {
            setStartDate(nowAsDMYFormat);
          }
          if (startInputAsDate > endInputAsDate) {
            setEndDate(value);
          }
        } else if (validateDMYFormat(value)) {
          setStartDate(nowAsDMYFormat);
        }
        break;
      }
      case 'end': {
        const startInputAsDate = parseDateStringToDate(startDate)
        const endInputAsDate = parseDateStringToDate(value)
        setEndDate(value);
        if (validateDateFormat(value)) {
          if (endInputAsDate < startInputAsDate) {
            setEndDate(startDate);
          }
        } else if (validateDMYFormat(value)) {
          setEndDate(startDate);
        }
        break;
      }
      case 'startTime': {
        const timeNow = extractTimeFromDateString(new Date())
        setStartTime(value)
        if (isValid24HourTime(value)) {
          if (validateEndTime(value, endTime, duration)) {
            const timeEnd = increaseTimeOnTimeOnly(value, duration)
            setEndTime(timeEnd)
          }
        } else if (validateTimeFormat(value)) {
          setStartTime(timeNow)
        }
        break;
      }
      case 'endTime': {
        setEndTime(value);
        break;
      }
      case 'duration': {
        setDuration(value)
        if (validateTimeFormat(value)) {
          if (value < '00:01') {
            setDuration('00:01')
          }
          if (value > '23:59') {
            setDuration('23:59')
          }
        }
        break
      }
      default:
        break;
    }
  }

  return (
    <>
      <Styled.Header>
        <TitleCustom
          name={`
            Agenda Profissional ${
              profissionalNome ? `de ${profissionalNome}` : ''
            }
          `}
        />
        <Styled.HeaderButtonsContainer>
          <Styled.CreateAppointmentButton
            style={{ backgroundColor: '#3b5179' }}
            onClick={() => setAdicionarModalVisible(true)}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              className="w-6 h-6 mr-2"
            >
              <path strokeLinecap="round" strokeLinejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
            </svg>
            Nova Agenda
          </Styled.CreateAppointmentButton>
          {profissionalNome &&
            <ButtonReturnCustom
              link="/secretariado/meus-profissionais"
              shouldFillSpaces
            />
          }
        </Styled.HeaderButtonsContainer>
      </Styled.Header>
      <Styled.FullCalendarContainer>
        {isLoading
          && <Styled.LoadingContainer>
            <SpinnerCustom />
          </Styled.LoadingContainer>
        }
        <FullCalendar
          plugins={plugins}
          initialView="dayGridMonth"
          locale={ptLocale}
          headerToolbar={{
            left: 'prev,next today',
            center: 'title',
            right: 'timeGridDay,timeGridWeek,dayGridMonth,multiMonthYear,listWeek',
          }}
          events={eventos}
          eventContent={customEventContent}
          editable={true}
          selectable={true}
          select={handleDateSelect}
          dayMaxEventRows={true}
          dayMaxEvents={true}
          eventClick={handleEventClick}
          slotLabelFormat={{
            hour: '2-digit',
            minute: '2-digit',
            hour12: false
          }}
          eventTimeFormat={{
            hour: '2-digit',
            minute: '2-digit',
            hour12: false
          }}
          loading={(isLoadingNow) => setIsLoading(isLoadingNow)}
        />
      </Styled.FullCalendarContainer>
      <Modal
        title="Agendamento de Consulta"
        open={adicionarModalVisible}
        onCancel={closeCreateAppointmentModal}
        footer={null}
      >
        <form onSubmit={handleSalvarEvento}>
          <div className="grid gap-4 grid-cols-2">
            <div>
              <InputCustom
                label="Período Inicial"
                value={startDate}
                type="text"
                mask='99/99/9999'
                name="start"
                onChange={handleOnChange}
                placeholder="00/00/0000"
                required
                appearence='primary'
              />
            </div>
            <div>
              <InputCustom
                label="Período Final"
                value={endDate}
                type="text"
                mask='99/99/9999'
                name="end"
                onChange={handleOnChange}
                placeholder="00/00/0000"
                required
                appearence='primary'
              />
            </div>
            <div>
              <label className="block mb-1">Hora de Início:</label>
              <input
                type="time"
                name="startTime"
                value={startTime}
                onChange={handleOnChange}
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                placeholder="Hora de Início"
                required
              />
            </div>
            <div className="mb-6">
              <label className="block mb-1">Hora Final:</label>
              <input
                type="time"
                name="endTime"
                value={endTime}
                onChange={handleOnChange}
                placeholder="Hora Final"
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                required
              />
            </div>
          </div>
          <legend className="mb-4">Dias da semana:</legend>
          <div className="grid grid-cols-2 gap-4">
            <fieldset className="max-w-md">
              <CheckBoxAgendaCustom
                label='Segunda-feira'
                value='01'
                name='monday'
              />
              <CheckBoxAgendaCustom
                label='Terça-feira'
                value='02'
                name='tuesday'
              />
              <CheckBoxAgendaCustom
                label='Quarta-feira'
                value='03'
                name='wednesday'
              />
              <CheckBoxAgendaCustom
                label='Quinta-feira'
                value='04'
                name='thursday'
              />
            </fieldset>
            <fieldset className="max-w-md">
              <CheckBoxAgendaCustom
                label='Sexta-feira'
                value='05'
                name='friday'
              />
              <CheckBoxAgendaCustom
                label='Sábado'
                value='06'
                name='saturday'
              />
              <CheckBoxAgendaCustom
                label='Domingo'
                value='07'
                name='sunday'
              />
            </fieldset>
          </div>
          <div className="mb-6">
            <InputCustom
              label="Tempo de Consulta:"
              value={duration}
              type="text"
              mask='99:99'
              name="duration"
              onChange={handleOnChange}
              placeholder="00:00"
              required
              appearence='primary'
            />
          </div>
          <div className="flex justify-end mt-6">
            <button
              type="submit"
              className="py-2.5 px-5 text-sm font-medium text-white focus:ring-4 rounded-lg"
              style={{ backgroundColor: '#a7eb51' }}
            >
              Salvar
            </button>
          </div>
          {showMessage && <Notification mode="warning" message={showMessage} />}
        </form>
      </Modal>

      <Modal
        title={`Eventos do Dia - ${selectedDate ? parseDateToDMYFormat(selectedDate) : ''}`}
        open={consultModalVisible}
        onCancel={() => {
          setConsultModalVisible(false);
          setSelectedDate(null);
        }}
        footer={null}
        style={{ minWidth: '600px', maxWidth: '90%', margin: '20px auto' }}
      >
        {selectedEvent && (
          <div className="mb-4">
            <div className="border-b mb-4 justify-between items-center">
              <div>
                <table
                  className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400 mb-3">
                  <thead
                    className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                    <tr>
                      <th scope="col" className="px-6 py-3">
                        Horário
                      </th>
                      <th scope="col" className="px-6 py-3">
                        Status
                      </th>
                      <th scope="col" className="px-6 py-3">
                        Paciente
                      </th>
                      <th scope="col" className="px-6 py-3">
                        Opções
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {selectedEvent.map((event, index) => (
                      <tr key={index}
                        className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600 ">
                        <td scope="row"
                          className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                          {extractTimeFromDateString(new Date(event.start))}
                        </td>
                        <td scope="row"
                          className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                          {(event.status.includes('-') ? event.status.slice(2) : event.status) || "N/I"}
                        </td>
                        <td scope="row"
                          className="px-4 py-3 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                          {event.paciente || ""}
                        </td>
                        <td className="px-6 py-3">
                          <div className="flex items-center">
                            <ButtonQueryCustom
                              link={'/dados-profissionais/agenda/atendimento-consulta/' + event.id}
                            />
                            {canDeleteAgenda(event.status, event.start) &&
                              <ButtonDeleteCustom link={"/agenda/" + event.id} />
                            }
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                <p className='mb-3'>Total de consultas: { daysAppointmentAmount(selectedEvent, selectedDate) }</p>
              </div>
            </div>
          </div>
        )}
      </Modal>

      <Modal
        title="Detalhes da Consulta"
        open={modalVisible}
        onCancel={() => setModalVisible(false)}
        footer={null}
        destroyOnClose={true}
      >
        {eventDetails && (
          <div>
            <div className='mb-6'>
              <p><strong>Status da Consulta:</strong> {eventDetails.status}</p>
              <p>
                <strong>Data:</strong>
                {` ${parseDateToDMYFormat(new Date(eventDetails.start))} - ${extractTimeFromDateString(new Date(eventDetails.start))}`}
              </p>
              {canAccessPatientInfo(eventDetails.pureStatus) && 
                <>
                  <p><strong>Paciente:</strong> {eventDetails.paciente}</p>
                  <p><strong>CPF:</strong> {eventDetails.cpf_cnpj}</p>
                  <p><strong>Data de Nascimento:</strong> {eventDetails.dta_nascimento}</p>
                  <p><strong>Gênero:</strong> {eventDetails.genero}</p>
                  <p><strong>Profissão:</strong> {eventDetails.profissao}</p>
                  <p><strong>Telefone:</strong> {eventDetails.telefone}</p>
                  <p><strong>Email:</strong> {eventDetails.email}</p>                  
                </>
              }
              {!canAccessAgenda(eventDetails.pureStatus) && 
                <div className="mt-2"> Para ir a consulta, esta deve estar <strong>Confirmada</strong> ou <strong>Em Andamento</strong></div>
              }
            </div>
            {canAccessAgenda(eventDetails.pureStatus) &&
              <button
                onClick={navigateToAtendimentoConsulta}
                type="button"
                className="text-white bg-blue-900 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:bg-blue-900 dark:hover:bg-blue-800 focus:outline-none dark:focus:ring-blue-900"
                style={{ backgroundColor: '#598c89' }}
              >
                Ir Para Consulta
              </button>
            }
            {canAccessPatientInfo(eventDetails.pureStatus) &&
              <Link
                to={'/dados-profissionais/meus-pacientes/dados-do-paciente/' + eventDetails.pacienteId}
                type="button"
                className="text-white bg-blue-900 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:bg-blue-900 dark:hover:bg-blue-800 focus:outline-none dark:focus:ring-blue-900"
                style={{ backgroundColor: '#598c89' }}
              >
                Prontuario
              </Link>
            }
            {canDeleteAgenda(eventDetails.pureStatus, eventDetails.start) &&
              <ButtonDeleteAgendaCustom
                deleteAppointment={deleteAppointment}
                id={eventDetails.id}
              />}
          </div>
        )}
      </Modal>
    </>
  );
};

export default AgendaProfissional;
