import React, { useState, useEffect } from "react";
import { TitleCustom } from "../../../../../components/titleCustom/TitleCustom";
import { ButtonReturnCustom } from "../../../../../components/ButtonReturnCustom/ButtonReturnCustom";
import { ButtonSaveCustom } from "../../../../../components/ButtonSaveCustom";
import InputCustom from "../../../../../components/InputCustom";
import { pessoa_telefoneModel, pessoaModel } from "../../../../../resource/request";
import withRouter from "../../../../../helpers/withRouter";
import { store } from "../../../../../redux/store";
import api_app from "../../../../../apis/api_app";
import { ToggleCustom } from "../../../../../components/ToggleCustom";
import SpinnerCustom from "../../../../../components/SpinnerCustom";
import { useDispatch } from "react-redux";
import { createUser } from "../../../../../redux/user/slice";

const EditTelefone = (props: any) => {
    const [telefone, setTelefone] = useState<pessoa_telefoneModel>({} as pessoa_telefoneModel);
    const [telefonePrincipal, setTelefonePrincipal] = useState<pessoa_telefoneModel>({} as pessoa_telefoneModel);
    const [msg, setMsg] = useState<string>("");
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [isSubmitted, setIsSubmitted] = useState<boolean>(false);
    const [user, setUser] = useState<pessoaModel>(store.getState().user.user);
    const [emailPrincipal, setEmailPrincipal] = useState<String>(store.getState().user.emailPrincipal);
    let dispach = useDispatch();

    const handleChange = (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        const { name, value } = event.target;
        setTelefone((prevTelefone) => ({
            ...prevTelefone,
            [name]: value,
        }));
    };

    const updateStore = () => {     
        api_app.findByPessoa(emailPrincipal).then(result => {
            if (result) {
              dispach(createUser(result));
            } 
        });   
    }

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        api_app.atualizarTelefone(telefone.id, telefone).then((x) => {
            setMsg("Seu telefone foi atualizado com sucesso!");
            setIsSubmitted(true);
            updateStore();
        });
    };

    useEffect(() => {
        const id = props.router.params.id;
        const telefoneResponse = user.pessoa_telefone;

        telefoneResponse.forEach((telefone) => {
            if (telefone.id.toString() === id.toString()) {
                setTelefone(telefone);
                setIsLoading(false);
            }
            if (telefone.flg_principal === "S" && telefone.id.toString() !== id.toString()) {
                setTelefonePrincipal(telefone);
            }
        });
    }, []);

    if (isLoading) {
        return (
            <div>
                <SpinnerCustom />
            </div>
        );
    }

    return (
        <>
            <form onSubmit={handleSubmit}>
                <div className="flex items-center justify-between rounded-t dark:border-gray-600">
                    <TitleCustom name="Alterar Telefone" />
                    <ButtonReturnCustom link="/perfil" />
                </div>
                <div className="grid gap-6 mb-6">
                    <InputCustom
                        label="Telefone:"
                        value={telefone?.telefone?.toString() || ""}
                        type="text"
                        mask="(99) 9 99999999"
                        name="telefone"
                        placeholder="(00) 0 00000000"
                        onChange={handleChange}
                    />
                    <InputCustom
                        label="Descrição:"
                        value={telefone?.observacao?.toString() || ""}
                        type="text"
                        name="observacao"
                        placeholder="Descrição"
                        onChange={handleChange}
                    />
                    <ToggleCustom
                        label="Telefone Principal?"
                        value={telefone?.flg_principal || "S"}
                        name="flg_principal"
                        onChange={handleChange}
                        disabled={telefonePrincipal?.id !== undefined}
                        defaultValue={telefone?.flg_principal || "N"}
                    />
                </div>
                <div className="flex items-center mt-6 space-x-4 rtl:space-x-reverse">
                    {!isSubmitted && (
                        <ButtonSaveCustom type="submit" name="Salvar" />
                    )}
                </div>
                <p className="mt-2 text-sm text-green-600 dark:text-green-500">{msg}</p>
            </form>
        </>
    );
};

export default withRouter(EditTelefone);
