import * as Styled from './styles'

export const TitleCustom = (props) => {
  return (
    <>
      <Styled.TitleContainer>
        <Styled.Title>
          {props.name}
        </Styled.Title>
      </Styled.TitleContainer>   
    </>
  );
}