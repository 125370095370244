import { useEffect, useState } from "react";
import { TitleCustom } from "../../../components/titleCustom/TitleCustom";
import InputSearchCustom from '../../../components/InputSearchCustom'
import api_app from "../../../apis/api_app";
import * as Styled from './styles'
import SpinnerCustom from "../../../components/SpinnerCustom";
import { inputedType } from "../../../helpers/stringTypeHelper";
import { parseDateToDMYFormat } from "../../../helpers/date_helpers";
import { useNavigate } from "react-router-dom";
import { CiCalendarDate, CiCirclePlus } from "react-icons/ci";

export const MeusProfissionais = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true)
  const [professionals, setProfessionals] = useState([]);
  const [filteredProfessionals, setFilteredProfessionals] = useState(null)
  const [filter, setFilter] = useState({});
  const [professionalInput, setProfessionalInput] = useState('');
  const [emptyMessage, setEmptyMessage] = useState("Não há ninguém na sua lista de profissionais.")
  const professionalsToMap = filteredProfessionals ?? professionals

  const fetchProfessionals = async () => {
    try {
      const response = await api_app.getProfessionals();
      setProfessionals(response.data);
    } catch (error) {
      console.error(error);
    }
    setIsLoading(false);
  }

  useEffect(() => {
    fetchProfessionals();
  }, [])

  const handleChange = (event) => {
    const { name, value } = event.target
    switch (name) {
      case 'search':{
        setProfessionalInput(value)
        setFilter({
          [inputedType(value)]: value
        })
        break;
      }
      default:
        break;
    }
  }

  const handleSearch = () => {
    const key = Object.keys(filter)[0]
    if (!filter[key]) {
      setFilteredProfessionals(null)
    } else {
      const result = professionals.filter(professional => professional[key].includes(filter[key]))
      if (result.length === 0) {
        setEmptyMessage("Sua procura não encontrou nenhum profissional.")
      }
      setFilteredProfessionals(result)
    }
  }

  const handleClean = () => {
    setProfessionalInput('')
    setFilteredProfessionals(null)
  }

  const handleOnViewAgenda = (professional) => {
    navigate(`/dados-profissionais/agenda?profissional_id=${professional.id}&profissional_nome=${professional.nome}`)
  }

  const handleOnAppointment = (id) => {
    navigate(`/agendamento-de-consulta/agendar-consulta/${id}?profissional_id=${id}`)
  }

  const tableColumns = (
    <Styled.TableHead>
      <tr>
        <th scope="col" className="px-6 py-3">
          Nome
        </th>
        <th scope="col" className="px-6 py-3">
          CPF
        </th>
        <th scope="col" className="px-6 py-3">
          Data de Nascimento
        </th>
        <th scope="col" className="px-6 py-3">
          Opções
        </th>
      </tr>
    </Styled.TableHead>
  )

  const tableContent = () => {
    if (professionalsToMap?.length === 0) {
      return (
        <Styled.TableRow key={'07'}>
          <Styled.TableEmptyMessage colSpan={14}>
            {emptyMessage}
          </Styled.TableEmptyMessage>
        </Styled.TableRow>
      )
    } else {
      return (
        professionalsToMap?.map((professional) => (
          <Styled.TableRow key={professional.id}>
            <Styled.TableHeader>{professional.nome}</Styled.TableHeader>
            <Styled.TableData>{professional.cpf_cnpj}</Styled.TableData>
            <Styled.TableData>{parseDateToDMYFormat(professional.dat_nascimento_fundacao)}</Styled.TableData>
            <Styled.TableData>
              <button onClick={() => handleOnViewAgenda(professional)}>
                <CiCalendarDate size={24} />
              </button>
              <Styled.ButtonPlus onClick={() => handleOnAppointment(professional.id)}>
                <CiCirclePlus size={24} />
              </Styled.ButtonPlus>
            </Styled.TableData>
          </Styled.TableRow>
        ))
      )
    }
  }

  return (
    <>
      <Styled.TitleContainer>
        <TitleCustom name='Meus Profissionais' />
      </Styled.TitleContainer>
      <Styled.HeaderOptionsContainer>
        <Styled.SearchContainer>
          <InputSearchCustom
            name="search"
            value={professionalInput}
            onChange={handleChange}
          />
          {professionalInput &&
            <Styled.CleanButton onClick={handleClean}>
              <Styled.IconClean />
            </Styled.CleanButton>
          }
          <Styled.SearchButton onClick={handleSearch}>
            <Styled.IconSearch />
            Pesquisar
          </Styled.SearchButton>
        </Styled.SearchContainer>
      </Styled.HeaderOptionsContainer>
      {isLoading ? (
        <Styled.SpinnerContainer>
          <SpinnerCustom />
        </Styled.SpinnerContainer>
      ) : (
        <Styled.TableContainer>
          <Styled.Table>
            {tableColumns}
            <tbody>
              {tableContent()}
            </tbody>
          </Styled.Table>
        </Styled.TableContainer>
      )}
    </>
  )
}