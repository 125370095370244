import React, { useState, useEffect } from "react";
import { TitleCustom } from "../../../../../components/titleCustom/TitleCustom";
import { ButtonReturnCustom } from "../../../../../components/ButtonReturnCustom/ButtonReturnCustom";
import { ButtonSaveCustom } from "../../../../../components/ButtonSaveCustom";
import InputCustom from "../../../../../components/InputCustom";
import { pessoa_enderecoModel, pessoaModel } from "../../../../../resource/request";
import { store } from "../../../../../redux/store";
import withRouter from "../../../../../helpers/withRouter";
import api_app from "../../../../../apis/api_app";
import { ToggleCustom } from "../../../../../components/ToggleCustom";
import SpinnerCustom from "../../../../../components/SpinnerCustom";
import { useDispatch } from "react-redux";
import { createUser } from "../../../../../redux/user/slice";

const EditEndereco = (props: any) => {
    const [endereco, setEndereco] = useState<pessoa_enderecoModel>({} as pessoa_enderecoModel);
    const [enderecoPrincipal, setEnderecoPrincipal] = useState<pessoa_enderecoModel>({} as pessoa_enderecoModel);
    const [msg, setMsg] = useState<string>("");
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [isSubmitted, setIsSubmitted] = useState<boolean>(false);
    const [user, setUser] = useState<pessoaModel>(store.getState().user.user);
    const [emailPrincipal, setEmailPrincipal] = useState<String>(store.getState().user.emailPrincipal);
    let dispach = useDispatch();
     

    const handleChange = (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        const { name, value } = event.target;
        setEndereco((prevEndereco) => ({
            ...prevEndereco,
            [name]: value,
        }));
    };

    const updateStore = () => {     
        api_app.findByPessoa(emailPrincipal).then(result => {
            if (result) {
              dispach(createUser(result));
            } 
        });   
    }
    
    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        api_app.atualizarEndereco(endereco.id, endereco).then(() => {
            setMsg("Seu endereço foi atualizado com sucesso!");
            setIsSubmitted(true);
            updateStore();
        });
    };

    useEffect(() => {
        const id = props.router.params.id;
        const enderecoResponse = store.getState().user.user.pessoa_endereco;

        enderecoResponse.forEach((enderecoItem) => {
            if (enderecoItem.id.toString() === id.toString()) {
                setEndereco(enderecoItem);
                setIsLoading(false);
            }
            if (enderecoItem.flg_principal === "S" && enderecoItem.id.toString() !== id.toString()) {
                setEnderecoPrincipal(enderecoItem);
            }
        });
    }, []);

    if (isLoading) {
        return (
            <div>
                <SpinnerCustom />
            </div>
        );
    }

    return (
        <>
            <form onSubmit={handleSubmit}>
                <div className="flex items-center justify-between rounded-t dark:border-gray-600">
                    <TitleCustom name="Alterar Endereço" />
                    <ButtonReturnCustom link="/perfil" />
                </div>
                <div className="grid gap-6 mb-6 md:grid-cols-2">
                    <InputCustom
                        label="CEP:"
                        value={endereco?.cep || ""}
                        type="text"
                        mask="99999-999"
                        name="cep"
                        placeholder="CEP"
                        onChange={handleChange}
                    />
                    <InputCustom
                        label="País:"
                        value={endereco?.pais || ""}
                        type="text"
                        name="pais"
                        placeholder="País"
                        onChange={handleChange}
                    />
                    <InputCustom
                        label="Estado:"
                        value={endereco?.estado || ""}
                        type="text"
                        name="estado"
                        placeholder="Estado"
                        onChange={handleChange}
                    />
                    <InputCustom
                        label="Cidade:"
                        value={endereco?.cidade || ""}
                        type="text"
                        name="cidade"
                        placeholder="Cidade"
                        onChange={handleChange}
                    />
                    <InputCustom
                        label="Bairro:"
                        value={endereco?.bairro || ""}
                        type="text"
                        name="bairro"
                        placeholder="Bairro"
                        onChange={handleChange}
                    />
                    <InputCustom
                        label="Rua:"
                        value={endereco?.endereco || ""}
                        type="text"
                        name="endereco"
                        placeholder="Rua"
                        onChange={handleChange}
                    />
                    <InputCustom
                        label="Número:"
                        value={endereco?.numero || ""}
                        type="number"
                        name="numero"
                        placeholder="Número"
                        onChange={handleChange}
                    />
                    <InputCustom
                        label="Complemento:"
                        value={endereco?.complemento || ""}
                        type="text"
                        name="complemento"
                        placeholder="Complemento"
                        onChange={handleChange}
                    />
                    <InputCustom
                        label="Descrição:"
                        value={endereco?.observacao || ""}
                        type="text"
                        name="observacao"
                        placeholder="Descrição"
                        onChange={handleChange}
                    />
                    <div className="flex items-center">
                        <ToggleCustom
                            label="Endereço Principal?"
                            value={endereco?.flg_principal || "S"}
                            name="flg_principal"
                            onChange={handleChange}
                            disabled={enderecoPrincipal?.id !== undefined}
                            defaultValue={endereco?.flg_principal || "N"}
                        />
                    </div>
                </div>
                <div className="flex items-center mt-6 space-x-4 rtl:space-x-reverse">
                    {!isSubmitted && <ButtonSaveCustom type="submit" name="Salvar" />}
                </div>
                <p className="mt-2 text-sm text-green-600 dark:text-green-500">{msg}</p>
            </form>
        </>
    );
};

export default withRouter(EditEndereco);
