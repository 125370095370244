import { useState, useRef } from 'react';
import { Form, Header, ButtonContainer, Button } from './styles';
import StepIndicator from './stepIndicator/stepIndicator';
import DadosCadastrais from './stepsContent/dadosCadastrais';
import ResumoDoCurriculo from './stepsContent/resumoDoCurriculo';
import api_app from '../../apis/api_app';

const RegisterNewUser = ({
  isSelfRegistration = false,
  searchParams,
  setSearchParams,
  setNotificationMessage
}) => {
  const formRef = useRef(null);
  const [userType, setUserType] = useState('');
  const [pessoa, setPessoa] = useState();
  const patientSteps = ['Dados Cadastrais', 'Dados de Endereço', 'Documentos Pessoais'];
  const professionalSteps = [...patientSteps, 'Documentos Profissionais', 'Especialidades Profissionais', 'Resumo do Currículo'];
  const [currentStep, setCurrentStep] = useState(0);
  const [allSteps, setAllSteps] = useState(isSelfRegistration ? patientSteps : [patientSteps[0]]);

  const handleUserTypeChange = (e) => {
    const selectedType = e.target.value;
    setUserType(selectedType);
    setAllSteps(isSelfRegistration ? (
      selectedType === 'profissional' ? professionalSteps : patientSteps
    ) : (
      selectedType === 'profissional' ? [professionalSteps[0], professionalSteps[5]] : [patientSteps[0]]
    ));
  };

  const updatePessoaObj = (formDataEntries, callback) => {
    setPessoa((prevPessoa) => {
      const updatedPessoa = {
        ...prevPessoa,
        id: 0,
        nome: formDataEntries?.fullName || prevPessoa?.nome,
        dat_nascimento_fundacao: formDataEntries?.birthDate ? new Date(formDataEntries.birthDate) : prevPessoa?.dat_nascimento_fundacao,
        cpf_cnpj: formDataEntries?.cpf || prevPessoa?.cpf_cnpj,
        tipo_usuario_id: prevPessoa?.tipo_usuario_id || formDataEntries?.userType === 'profissional' ? 2 : 3,
        pessoa_email: formDataEntries?.email ? [
          {
            flg_visivel: "S",
            flg_principal: "S",
            flg_status: "A",
            dat_criado_em: new Date(),
            email: formDataEntries.email,
          },
        ] : prevPessoa?.pessoa_email,
        pessoa_telefone: formDataEntries?.phone ? [
          {
            telefone: formDataEntries.phone,
            flg_visivel: "S",
            flg_principal: "S",
            flg_status: "A",
          },
        ] : prevPessoa?.pessoa_telefone,
        pessoa_endereco: prevPessoa?.pessoa_endereco,
        pessoa_especialidade: prevPessoa?.pessoa_especialidade,
        pessoa_profissional: {
          uf: formDataEntries?.uf || '',
          rqe: formDataEntries?.rqe || '',
          resumo_curriculo: formDataEntries?.resume || ''
        }
      };

      if (callback) callback(updatedPessoa);

      return updatedPessoa;
    });
  };

  const handleNextStep = () => {
    const formData = new FormData(formRef.current);
    const data = Object.fromEntries(formData.entries());
    updatePessoaObj(data);
    setCurrentStep(currentStep + 1);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData(formRef.current);
    const data = Object.fromEntries(formData.entries());
  
    updatePessoaObj(data, async (updatedPessoa) => {
      if (!isSelfRegistration) {
        try {
          const keycloakResponse = await createNewKeycloakUser(updatedPessoa);
          if (keycloakResponse?.status === 201) {
            createNewPessoa(updatedPessoa);
          };
          setNotificationMessage({ mode: 'success', message: `Usuário ${updatedPessoa.nome} criado com sucesso!` });
        } catch (error) {
          setNotificationMessage({ mode: 'error', message: error.response?.data?.message })
        }

        searchParams.delete("modal");
        setSearchParams(searchParams);
      } else {
        try {
          createNewPessoa(updatedPessoa);
        }
        catch (error) {
          console.error("Error creating user: ", error);
        }
      }
    });
  };
  
  const createNewKeycloakUser = async (updatedPessoa) => {
    const response = await api_app.createNewKeycloakUser(updatedPessoa);
    return response;
  };

  const createNewPessoa = (updatedPessoa) => {
    api_app.criarPessoa(updatedPessoa).then(result => {
      if (updatedPessoa.tipo_usuario_id === 2) {
        api_app.criarPessoaProfissional(result.data.id, {
          pessoa_id: result.data.id,
          flg_status: 'A',
          flg_visivel: 'S',
          uf_certidao_registro_profissional: updatedPessoa?.pessoa_profissional?.uf,
          carteira_conselho: '',
          certidao_registro_profissional: updatedPessoa?.pessoa_profissional?.rqe,
          resumo_curriculo: updatedPessoa?.pessoa_profissional?.resumo_curriculo,
          certidao_regularidade: '',
          dat_criado_em: new Date()
        });
      }
    });
  };

  return (
    <Form ref={formRef} onSubmit={handleSubmit}>
      <Header>
        <StepIndicator steps={allSteps} currentStep={currentStep} />
      </Header>

      {currentStep === 0 && <DadosCadastrais userType={userType} handleUserTypeChange={handleUserTypeChange} isSelfRegistration={isSelfRegistration} />}
      {currentStep === 1 && userType === 'profissional' && <ResumoDoCurriculo />}

      <ButtonContainer>
        {currentStep === allSteps.length - 1
          ? <Button type="submit">Salvar</Button>
          : <Button type="button" onClick={handleNextStep}>Próximo</Button>
        }
      </ButtonContainer>
    </Form>
  );
};

export default RegisterNewUser;