import { Component } from "react";
import { TitleCustom } from "../../../../../components/titleCustom/TitleCustom";
import { ButtonReturnCustom } from "../../../../../components/ButtonReturnCustom/ButtonReturnCustom";
import { ButtonSaveCustom } from "../../../../../components/ButtonSaveCustom";
import InputCustom from "../../../../../components/InputCustom";
import { instituicao_financeiraModel, pessoa_instituicao_financeiraModel, tipo_conta_financeiraModel } from "../../../../../resource/request";
import SelectCustom, { Option } from "../../../../../components/SelectCustom";

interface State {
    agencia: pessoa_instituicao_financeiraModel,
    instituicao_financeira: Option[];
    tipo_conta: Option[];
}

export class CreateDadosBancarios extends Component {

    state: State = {
        agencia: {} as pessoa_instituicao_financeiraModel,
        instituicao_financeira: [{ key: "1", value: "Carregando...", disabled: true }],
        tipo_conta: [{ key: "1", value: "Carregando...", disabled: true }],
    }
    render() {
        return <>
            <form>
                <div className="flex items-center justify-between rounded-t dark:border-gray-600">
                    <TitleCustom
                        name="Adicionar Conta Bancária"
                    />
                    <ButtonReturnCustom
                        link="/dados-financeiros"
                    />
                </div>
                <div className="grid gap-6 mb-6 md:grid-cols-2">
                    <SelectCustom
                        label="Instituição Financeira:"
                        name="instituicao_financeira"
                        defaultValue="Selecione"
                        options={this.state.instituicao_financeira }
                    />

                    <InputCustom
                        label="Agência:"
                        value={this.state?.agencia?.agencia || ''}
                        type="number"
                        name="agencia"
                        placeholder="Agência"
                    />
                    <InputCustom
                        label="Conta:"
                        value={this.state?.agencia?.conta_dig_verificador || ''}
                        type="number"
                        name="conta_dig_verificador"
                        placeholder="Dígito Verificador"
                    />
                    <SelectCustom
                        label="Tipo de Conta:"
                        name="tipo_conta"
                        defaultValue="Selecione"
                        options={this.state.tipo_conta}
                    />
                </div>
                <div>
                    <ButtonSaveCustom
                        type="submit"
                        name="Salvar"
                    />
                </div>
            </form>
        </>
    }
}