import React, { Component } from "react";
import Nav from "./Nav";
import SideBar from "./sideBar/SideBar";
import {store} from "../redux/store";
import SpinnerCustom from "../components/SpinnerCustom";

import {keycloak} from "../keycloak";
import api_app from "../apis/api_app";
import {createUser} from "../redux/user/slice";

export class Layout extends Component<any> {

    constructor(props:  any) {
        super(props);
        this.state = {
            login: false
        }
    }



    componentDidMount() {

        if (localStorage.getItem('color-theme') === 'dark' || (!('color-theme' in localStorage) && window.matchMedia('(prefers-color-scheme: dark)').matches)) {
            document.documentElement.classList.add('dark');
            document.getElementById('theme-toggle-dark-icon')?.classList.add('hidden');
            document.getElementById('theme-toggle-light-icon')?.classList.remove('hidden');
        } else {
            document.documentElement.classList.remove('dark')
        }
    }

    darkModeHandler = () => {
        // if set via local storage previously
        if (localStorage.getItem('color-theme')) {
            if (localStorage.getItem('color-theme') === 'light') {
                document.documentElement.classList.add('dark');
                document.getElementById('theme-toggle-dark-icon')?.classList.add('hidden');
                document.getElementById('theme-toggle-light-icon')?.classList.remove('hidden');
                localStorage.setItem('color-theme', 'dark');
            } else {
                document.documentElement.classList.remove('dark');
                document.getElementById('theme-toggle-dark-icon')?.classList.remove('hidden');
                document.getElementById('theme-toggle-light-icon')?.classList.add('hidden');
                localStorage.setItem('color-theme', 'light');
            }
        } else {
            if (!document.documentElement.classList.contains('dark')) {
                document.documentElement.classList.remove('dark');
                localStorage.setItem('color-theme', 'light');
            } else {
                document.documentElement.classList.add('dark');
                localStorage.setItem('color-theme', 'dark');
            }
        }
    }

    render() {



        return <>
            <nav className="fixed top-0 z-50 w-full bg-white border-b border-gray-200 dark:bg-gray-800 dark:border-gray-700">
                <div className="px-3 py-3 lg:px-5 lg:pl-3">
                    <div className="flex items-center justify-between">
                        <div className="flex items-center justify-between rtl:justify-end">
                          

                            <button onClick={this.darkModeHandler} id="theme-toggle" type="button" className="text-gray-500 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-700 focus:outline-none focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 rounded-lg text-sm p-2.5">
                                <svg id="theme-toggle-dark-icon" className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M17.293 13.293A8 8 0 016.707 2.707a8.001 8.001 0 1010.586 10.586z"></path>
                                </svg>
                                <svg id="theme-toggle-light-icon" className="hidden w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M10 2a1 1 0 011 1v1a1 1 0 11-2 0V3a1 1 0 011-1zm4 8a4 4 0 11-8 0 4 4 0 018 0zm-.464 4.95l.707.707a1 1 0 001.414-1.414l-.707-.707a1 1 0 00-1.414 1.414zm2.12-10.607a1 1 0 010 1.414l-.706.707a1 1 0 11-1.414-1.414l.707-.707a1 1 0 011.414 0zM17 11a1 1 0 100-2h-1a1 1 0 100 2h1zm-7 4a1 1 0 011 1v1a1 1 0 11-2 0v-1a1 1 0 011-1zM5.05 6.464A1 1 0 106.465 5.05l-.708-.707a1 1 0 00-1.414 1.414l.707.707zm1.414 8.486l-.707.707a1 1 0 01-1.414-1.414l.707-.707a1 1 0 011.414 1.414zM4 11a1 1 0 100-2H3a1 1 0 000 2h1z" fillRule="evenodd" clipRule="evenodd"></path>
                                </svg>
                            </button>

                            <a href="/" className="flex items-center ml-2 md:mr-24">
                                <img src="/logo01.png" className="h-11 mr-3" alt="Sec Logo" />
                                <span className="self-center text-xl font-semibold sm:text-2xl whitespace-nowrap dark:text-white">SEC24</span>
                            </a>
                        </div>
                        <div>
                            <Nav />
                        </div>
                    </div>
                </div>
            </nav>
            <div>

             
                    <SideBar />
               
            </div>


        </>
    }
}