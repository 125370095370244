import styled from "styled-components";

export const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem;
  border-radius: 0.375rem;
  border-color: ${({ theme }) => theme.dark ? '#4b5563' : 'transparent'};

  @media (min-width: 768px) {
    padding: 1.25rem;
  }
`;

export const Title = styled.h3`
  font-size: 1.125rem;
  font-weight: 600;
  color: ${({ theme }) => theme.dark ? '#fff' : '#1f2937'};
`;