import axios from "axios";
import { getIdToken } from "../keycloak";
const REACT_APP_BASE_URL = process.env.REACT_APP_BASE_URL;

const apis = axios.create({
  baseURL: REACT_APP_BASE_URL,
  headers: {
    'Content-Type': 'multipart/form-data',
  },
});

apis.interceptors.request.use(async (config) => {
  config.headers.Authorization = `Bearer ${getIdToken()}`;
  return config;
});

apis.interceptors.request.use(async (config) => {
    config.headers.setContentType('multipart/form-data')
    return config;
});


const api_app_upload = {

    register: async ( obj) => {
        let response = await apis.put(`/pessoa-documento`, obj);
        return response.data;
    },
}

export default api_app_upload;
