import React, { useEffect, useState } from "react";
import { Accordion, Timeline } from "flowbite-react";
import { agendaModel } from "../../../resource/request";
import SpinnerCustom from "../../../components/SpinnerCustom";
import api_app from "../../../apis/api_app";
import { store } from "../../../redux/store";
import { ProntuarioBox } from "./styles";
import { extractTimeFromDateString, parseDateToDMYFormat } from "../../../helpers/date_helpers";

function Prontuario() {
    const [minhaAgenda, setMinhaAgenda] = useState<agendaModel[]>([]);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const fetchAgenda = async () => {
            try {
                const response = await api_app.getAgendaPaciente({
                    "data_inicial": "",
                    "data_final": "",
                    "horario_inicial": "",
                    "horario_final": "",
                    "page": 1,
                    "limit": 50,
                    "pessoa_id": store.getState().user.user.id,
                });
                setMinhaAgenda(response.data.data.reverse());
            } catch (error) {
                console.error("Erro ao buscar agenda:", error);
            } finally {
                setIsLoading(false);
            }
        };

        fetchAgenda();
    }, []);

    return (

        <div className="overflow-x-auto p-4">
            {isLoading ? (
                <div className="flex justify-center items-center h-screen bg-gray-100">
                    <SpinnerCustom />
                </div>
            ) : (
                <Accordion>
                    {minhaAgenda?.map((agenda, index) => (
                        <Accordion.Panel key={index}>
                            <Accordion.Title className="bg-gray-200 p-4 rounded-lg shadow-md">
                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <p style={{ marginRight: '10px' }}>
                                        {agenda.data
                                            ? `${parseDateToDMYFormat(new Date(agenda.data))} - ${extractTimeFromDateString(new Date(agenda.data))} - `
                                            : '- - - '
                                        }
                                    </p>
                                    <p>
                                        Médico: {agenda.pessoa?.nome} - {agenda.especialidade?.especialidade}
                                    </p>
                                </div>
                            </Accordion.Title>
                            <Accordion.Content className="p-4">
                                <Timeline className="mt-4">
                                    <Timeline.Item>
                                        <Timeline.Content>
                                            <Timeline.Body>
                                                <Timeline.Body>
                                                    <Timeline.Body>
                                                        <Timeline.Body>
                                                            <ProntuarioBox
                                                                dangerouslySetInnerHTML={{
                                                                    __html: `${agenda.prontuario?.find((x) => x)?.parecer ||
                                                                        ''
                                                                    }`
                                                                }}
                                                            />
                                                        </Timeline.Body>
                                                    </Timeline.Body>
                                                </Timeline.Body>
                                            </Timeline.Body>
                                        </Timeline.Content>
                                    </Timeline.Item>
                                </Timeline>
                            </Accordion.Content>
                        </Accordion.Panel>
                    ))}
                </Accordion>
            )}
        </div>
    );
}

export default Prontuario;
