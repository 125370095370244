import apis from "./apis";
const api_app = {

  registrar: async (obj) => {
    let response = await apis.post("/agenda", obj);
    return response;
  },

  atualizarPessoa: async (id, obj) => {
    let response = await apis.patch(`/pessoa/${id}`, obj);
    return response;
  },

  criarPessoa: (obj) => {
    return apis.post('/pessoa', obj);
  },

  atualizarPessoaFisica: async (id, obj) => {
    let response = await apis.patch(`/pessoa/info/pessoa-fisica/${id}`, obj);
    return response;
  },

  atualizarPessoaPaciente: async (id, obj) => {
    let response = await apis.patch(`/pessoa/info/pessoa-paciente/${id}`, obj);
    return response;
  },

  atualizarPessoaProfissional: async (id, obj) => {
    let response = await apis.patch(`/pessoa/info/pessoa-profissional/${id}`, obj);
    return response;
  },

  delete: async (path) => {
    let response = await apis.patch(`${path}`, {
      flg_status: "E",
      flg_visivel: "N",

    });
    return response;
  },

  atualizarEndereco: async (id, obj) => {
    console.log(obj);
    let response = await apis.patch(`/pessoa/info/pessoa-endereco/${id}`, obj);
    return response;
  },

  atualizarEmail: async (id, obj) => {
    console.log(obj);
    let response = await apis.patch(`/pessoa/info/pessoa-email/${id}`, obj);
    return response;
  },

  criarEmail: async (id, obj) => {
    console.log(obj);
    let response = await apis.post(`/pessoa/info/pessoa-email/`, obj);
    return response;
  },

  findByPessoa: async (email) => {
    let response = await apis.get(`/pessoa/email/${email}`);
    return response.data;
  },

  findEspecialidades: async () => {
    let response = await apis.get(`/especialidade`);
    return response.data;
  },

  findProfissao: async () => {
    let response = await apis.get(`/profissao`);
    return response.data;
  },

  findTipoGenero: async () => {
    let response = await apis.get(`/tipo-genero`);
    return response.data;
  },

  findEtnia: async () => {
    let response = await apis.get(`/raca-etnia`);
    return response.data;
  },

  findTipoIdentidadeGenero: async () => {
    let response = await apis.get(`/tipo-indentidade-genero`);
    return response.data;
  },

  findTipoGeneroPronome: async () => {
    let response = await apis.get(`/tipo-genero-pronome`);
    return response.data;
  },

  findEstadoCivil: async () => {
    let response = await apis.get(`/estado-civil`);
    return response.data;
  },

  criarEndereco: async (obj) => {
    let response = await apis.post(`/pessoa/info/pessoa-endereco/`, obj);
    return response;
  },

  criartTelefone: async (obj) => {
    let response = await apis.post(`/pessoa/info/pessoa-telefone/`, obj);
    return response;
  },

  atualizarTelefone: async (id, obj) => {
    let response = await apis.patch(`/pessoa/info/pessoa-telefone/${id}`, obj);
    return response;
  },

  criarEspecialidade: async (obj) => {
    let response = await apis.post(`/pessoa/info/pessoa-especialidades/`, obj);
    return response;
  },

  atualizarEspecialidade: async (id, obj) => {
    let response = await apis.patch(`/pessoa/info/pessoa-especialidades/${id}`, obj);
    return response;
  },

  findTipoUsuario: async () => {
    let response = await apis.get(`/tipo-usuario`);
    return response.data;
  },

  findTipoDocumento: async () => {
    let response = await apis.get(`/tipo-documento`);
    return response.data;
  },

  getDoc: async (id) => {
    let response = await apis.get(`/pessoa-documento/${id}`);
    return response;
  },

  getPessoa: async (id) => {
    let response = await apis.get(`/pessoa/${id}`);
    return response;
  },

  getProfissional: async (id) => {
    let response = await apis.get(`/pessoa/profissionais/${id}`);
    return response;
  },

  getAgendaProfissionais: async (params) => {
    let response = await apis.get(`/agenda/profissionais`, { params });
    return response;
  },

  getAgendaProfissionaisById: async (id) => {
    let response = await apis.get(`/agenda/profissionais/${id}`);
    return response;
  },

  postAgendas: async (obj) => {
    let response = await apis.post(`/agenda`, obj);
    return response;
  },

  getAgendaPaciente: async (params) => {
    let response = await apis.get(`/agenda/paciente`, { params });
    return response;
  },

  agendarHorario: async (params) => {
    let response = await apis.post(`/agenda/paciente`, params);
    return response;
  },

  getAgendaProfissional: async () => {
    let response = await apis.get(`/agenda/profissional`);
    return response;
  },

  getAgendaProfissionalById: async (id) => {
    let response = await apis.get(`/agenda/profissional/${id}`);
    return response;
  },

  getProfissionais: async (params) => {
    let response = await apis.get(`/pessoa/profissionais`, { params });
    return response;
  },

  getMeusPacientes: async (params) => {
    let response = await apis.get(`/pessoa/meus-pacientes`, { params });
    return response;
  },

  getAgendaById: async (id) => {
    let response = await apis.get(`/agenda/${id}`);
    return response;
  },

  patchAgenda: async (id, obj) => {
    let response = await apis.patch(`/agenda/${id}`, obj);
    return response;
  },

  getProntuarioByAgendaId: async (id) => {
    return await apis.get(`/prontuario/agenda/${id}`)
  },

  postProntuario: async (obj) => {
    let response = await apis.post(`/prontuario`, obj);
    return response;
  },

  updateProntuario: async (id, obj) => {
    return await apis.patch(`/prontuario/${id}`, obj)
  },

  criarPessoaProfissional: async (id, obj) => {
    let response = await apis.post(`/pessoa/info/pessoa-profissional/`, obj);
    return response;
  },

  signUpPrescritorMemed: async (obj) => {
    const response = await apis.post('/memed/signup', obj);
    return response;
  },

  getPrescritorMemed: async (id) => {
    const response = await apis.get(`/memed/usuario/${id}`);
    return response;
  },

  searchUser: async (obj) => {
    const query = new URLSearchParams(obj).toString();
    const response = await apis.get(`/pessoa/pesquisar-usuario?${query}`);
    return response;
  },

  linkUserAsAssistant: async (obj) => {
    await apis.post('/pessoa/assistentes', obj);
  },

  getAssistants: async () => {
    return await apis.get(`/pessoa/meus-assistentes`);
  },

  getProfessionals: async () => {
    return await apis.get(`/pessoa/meus-profissionais`);
  },

  unlinkAssistant: async (id, assistantId) => {
    return await apis.delete(`/pessoa/assistentes`, { data: { profissional_id: id, assistente_id: assistantId } });
  },

  createNewKeycloakUser: async (formData) => {
    const pessoaObj = {
      username: formData.cpf_cnpj,
      enabled: true,
      email: formData?.pessoa_email?.[0]?.email,
      firstName: formData.nome.trim().split(" ")[0],
      lastName: formData.nome.trim().split(" ").slice(1).join(" "),
      credentials: [
        {
          type: "password",
          value: formData.cpf_cnpj,
          temporary: false
        }
      ]
    };
    return await apis.post('/keycloak/create-user', pessoaObj);
  },

  addUserToSecretariadoGroup: async (email) => {
    return await apis.put(`/keycloak/users/${email}/groups/secretariado`)
  },

  removeUserFromSecretariadoGroup: async (email) => {
    return await apis.delete(`/keycloak/users/${email}/groups/secretariado`)
  },

  getCkEditorToken: async () => {
    return await apis.get('/ckeditor/token')
  }
}
export default api_app;