import { ButtonEditCustom } from "./ButtonEditCustom";
import { ButtonDeleteCustom } from "./ButtonDeleteCustom";
import SpinnerCustom  from "./SpinnerCustom";

const AddressesTable = ({ addressesList, showOptions = true }) => {
    if (addressesList) {
        return (
            <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
                <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                    <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                        <tr>
                            <th scope="col" className="px-6 py-3">
                                CEP
                            </th>
                            <th scope="col" className="px-6 py-3">
                                Estado
                            </th>
                            <th scope="col" className="px-6 py-3">
                                Cidade
                            </th>
                            <th scope="col" className="px-6 py-3">
                                Bairro
                            </th>
                            <th scope="col" className="px-6 py-3">
                                Endereço
                            </th>
                            <th scope="col" className="px-6 py-3">
                                Número
                            </th>
                            <th scope="col" className="px-6 py-3">
                                Complemento
                            </th>
                            <th scope="col" className="px-6 py-3">
                                Principal
                            </th>
                           {showOptions &&
                            <th scope="col" className="px-6 py-3">
                                Opções
                            </th>
                            }
                        </tr>
                    </thead>
                    <tbody>
                        {addressesList.map((end) => {
                            const isMainAddress = end.flg_principal === 'S';

                            return (
                                <tr
                                    className={`bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600 ${end.flg_visivel === 'S' ? '' : 'hidden'}`}
                                    key={end.id}
                                >
                                    <td scope="row"
                                        className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                        {end.cep}
                                    </td>

                                    <td className="px-6 py-4">
                                        {end.estado}
                                    </td>
                                    <td className="px-6 py-4">
                                        {end.cidade}
                                    </td>
                                    <td className="px-6 py-4">
                                        {end.bairro}
                                    </td>
                                    <td className="px-6 py-4">
                                        {end.endereco}
                                    </td>
                                    <td className="px-6 py-4">
                                        {end.numero}
                                    </td>
                                    <td className="px-6 py-4">
                                        {end.complemento}
                                    </td>
                                    <td className="px-6 py-4">
                                        {isMainAddress ? 'Sim' : 'Não'}
                                    </td>
                                    {showOptions &&
                                    <td className="px-6 py-4">
                                        <div className="flex items-center">
                                            <ButtonEditCustom
                                                link={"/alterar-endereco/" + end.id}
                                            />
                                            {!isMainAddress && (
                                                <ButtonDeleteCustom
                                                    link={"/pessoa-endereco/" + end.id}
                                                />
                                            )}
                                        </div>
                                    </td>}
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
            </div>
        );
    }

    return <div><SpinnerCustom /></div>
};

export default AddressesTable;