import { Checkbox } from "flowbite-react";
import { Component } from "react";
import { ButtonCreateCustom } from "../../../../../components/ButtonCreateCustom";
import { TitleCustom } from "../../../../../components/titleCustom/TitleCustom";
import { ButtonEditCustom } from "../../../../../components/ButtonEditCustom";
import { ButtonDeleteCustom } from "../../../../../components/ButtonDeleteCustom";
import AddressesTable from "../../../../../components/AddressesTable";
import { pessoaModel } from "../../../../../resource/request";
import { store } from "../../../../../redux/store";
import InputSearchCuston from "../../../../../components/InputSearchCustom";

interface State {
    pessoa: pessoaModel;
    searchTerm: string;
}

export class ListEndereco extends Component<any> {

    state: State = {
        pessoa: store.getState().user?.user,
        searchTerm: "",
    };

    handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({ searchTerm: event.target.value });
    };

    getFilteredEnderecos() {
        const { searchTerm, pessoa } = this.state;
        if (!searchTerm) return pessoa?.pessoa_endereco || [];

        return (pessoa?.pessoa_endereco || []).filter((end) =>
            [end.cep, end.estado, end.cidade, end.bairro, end.endereco, end.numero, end.complemento]
                .map(field => field ?? '')
                .some(field => field.toLowerCase().includes(searchTerm.toLowerCase()))
        );
    }

    render() {
        const filteredEnderecos = this.getFilteredEnderecos();

        return <>
            <div className="text-lg font-semibold text-gray-900 dark:text-white">
                <TitleCustom name="Dados de Endereço" />
            </div>
            <div
                className="flex flex-column sm:flex-row flex-wrap space-y-4 sm:space-y-0 items-center justify-between pb-4">
                <ButtonCreateCustom link="/adicionar-endereco" name="Adicionar Endereço" />
                <InputSearchCuston
                    name="search"
                    value={this.state.searchTerm}
                    onChange={this.handleSearchChange}
                />
            </div>
            <AddressesTable addressesList={filteredEnderecos} />
        </>
    }
}