import React, {Component} from "react";
import {ButtonReturnCustom} from "../../../../../components/ButtonReturnCustom/ButtonReturnCustom";
import {TitleCustom} from "../../../../../components/titleCustom/TitleCustom";
import {ButtonSaveCustom} from "../../../../../components/ButtonSaveCustom";
import InputCustom from "../../../../../components/InputCustom";
import {pessoa_enderecoModel} from "../../../../../resource/request";
import api_app from "../../../../../apis/api_app";
import {store} from "../../../../../redux/store";
import withRouter from "../../../../../helpers/withRouter";
import axios from "axios";
import {ToggleCustom} from "../../../../../components/ToggleCustom";

class CreateEndereco extends Component<any, any> {

    constructor(props: any) {
        super(props);
        this.state = {
            endereco: {} as pessoa_enderecoModel,
            msg: "",
            isSubmitted: false,
            enderecoPrincipal: {} as pessoa_enderecoModel,
        };

    }

    componentDidMount() {
        store.getState().user.user.pessoa_endereco.map(endereco => {
            if (endereco.flg_principal === "S") {
                this.setState({enderecoPrincipal: endereco});
            }
        })
    }

    handleChange = (
        event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
    ) => {
        const {name, value} = event.target;
        this.setState({
            endereco: {
                ...this.state.endereco,
                [name]: value,
            },
        });
        if (name === "cep") {
            const cep = value.replace(/\D/g, "");


            if (cep.length === 8) {
                axios.get(`https://viacep.com.br/ws/${cep}/json/`).then((response) => {
                    const data = response.data;

                    if (!data.erro) {
                        this.setState({
                            endereco: {
                                ...this.state.endereco,
                                cep: data.cep,
                                pais: "Brasil",
                                estado: data.uf,
                                cidade: data.localidade,
                                bairro: data.bairro,
                                endereco: data.logradouro,
                                flg_visivel: "S",
                                flg_status: "A",
                                flg_principal: "N",
                                pessoa_id: store.getState().user.user.id
                            },
                        });
                    }
                });
            }
        }
    };

    handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        api_app.criarEndereco(this.state.endereco).then((x) => {
            const msg = "Seu endereço foi cadastrado com sucesso!";
            this.setState({msg, isSubmitted: true});
            this.props.updateStore();
        });
    };

    render() {
        return <>
            <form onSubmit={this.handleSubmit}>
                <div className="flex items-center justify-between rounded-t dark:border-gray-600">
                    <TitleCustom
                        name="Novo Endereço"
                    />
                    <ButtonReturnCustom
                        link="/perfil"
                    />
                </div>
                <div>


                    <div className="grid gap-6 mb-6 md:grid-cols-2">
                        <InputCustom
                            label="CEP:"
                            value={this.state.endereco?.cep || ""}
                            type="text"
                            mask="99999-999"
                            name="cep"
                            placeholder="CEP"
                            onChange={this.handleChange}
                            required
                        />
                        <InputCustom
                            label="País:"
                            value={this.state.endereco?.pais || ""}
                            type="text"
                            name="pais"
                            placeholder="País"
                            onChange={this.handleChange}
                            required
                        />
                        <InputCustom
                            label="Estado:"
                            value={this.state.endereco?.estado || ""}
                            type="text"
                            name="estado"
                            placeholder="Estado"
                            onChange={this.handleChange}
                            required
                        />
                        <InputCustom
                            label="Cidade:"
                            value={this.state.endereco?.cidade || ""}
                            type="text"
                            name="cidade"
                            placeholder="Cidade"
                            onChange={this.handleChange}
                            required
                        />
                        <InputCustom
                            label="Bairro:"
                            value={this.state.endereco?.bairro || ""}
                            type="text"
                            name="bairro"
                            placeholder="Bairro"
                            onChange={this.handleChange}
                            required
                        />
                        <InputCustom
                            label="Rua:"
                            value={this.state.endereco?.endereco || ""}
                            type="text"
                            name="endereco"
                            placeholder="Rua"
                            onChange={this.handleChange}
                            required
                        />
                        <InputCustom
                            label="Número:"
                            value={this.state.endereco?.numero || ""}
                            type="number"
                            name="numero"
                            placeholder="Número"
                            onChange={this.handleChange}
                            required
                        />
                        <InputCustom
                            label="Complemento:"
                            value={this.state.endereco?.complemento || ""}
                            type="text"
                            name="complemento"
                            placeholder="Complemento"
                            onChange={this.handleChange}
                        />
                        <InputCustom
                            label="Observação:"
                            value={this.state.endereco?.observacao || ""}
                            type="text"
                            name="observacao"
                            placeholder="Observação"
                            onChange={this.handleChange}
                        />
                        <div className="flex items-center">
                            <ToggleCustom
                                label="Endereço Principal?"
                                value={this.state?.endereco.flg_principal || 'S'}
                                name='flg_principal'
                                onChange={this.handleChange}
                                disabled={this.state?.enderecoPrincipal?.id !== undefined ? true : false}
                                defaultValue={this.state?.endereco.flg_principal || 'N'}
                            />
                        </div>
                    </div>
                </div>
                <p className="mt-2 text-sm text-green-600 dark:text-green-500">
                    {" "}
                    {this.state.msg}
                </p>
                <div className="flex items-center mt-6 space-x-4 rtl:space-x-reverse">
                    {!this.state.isSubmitted && (
                        <ButtonSaveCustom
                            type="submit"
                            name="Salvar"
                        />
                    )}
                </div>
            </form>
        </>
    }
}

export default withRouter(CreateEndereco);