import styled from 'styled-components';

export const StepIndicatorContainer = styled.div`
  display: flex;
  justify-content: center;
  position: relative;
  margin-bottom: 16px;
  gap: 24px;
`;

export const Step = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  transition: all 0.3s ease-in-out;
  opacity: ${(props) => (props.isActive ? 1 : 0.5)};
  transform: ${(props) => (props.isActive ? 'scale(1.1)' : 'scale(1)')};
`;

export const StepCircle = styled.div`
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: ${(props) => (props.isCompleted ? '#1e3a8a' : '#e5e7eb')};
  color: ${(props) => (props.isCompleted ? '#ffffff' : '#000000')};
`;

export const StepBar = styled.div`
  position: absolute;
  top: 50%;
  left: 0;
  height: 2px;
  background-color: ${(props) => (props.isCompleted ? '#1e3a8a' : '#d1d5db')};
  transition: width 0.3s ease;
  z-index: -1;
`;

export const StepLabel = styled.p`
  margin-top: 8px;
  margin-bottom: 20px;
  text-align: center;
  font-size: 12px;
  color: ${(props) => (props.darkMode ? '#ffffff' : '#000000')};
`;

export const ProgressBar = styled.div`
  position: absolute;
  top: 50%;
  left: 0;
  height: 2px;
  background-color: #1e3a8a;
  transform: translateY(-50%);
  transition: width 0.3s ease;
  z-index: -1;
  width: ${(props) => props.progressWidth};
`;