import styled from 'styled-components';

export const Form = styled.form`
  font-size: 14px;
`;

export const Header = styled.h1`
  text-align: center;
  color: #333;
`;

export const StepIndicator = styled.div`
  text-align: center;
  font-size: 14px;
  color: #666;
  margin-bottom: 20px;
`;

export const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: end;
`;

export const Button = styled.button`
    color: #ffffff;
    background-color: #a7eb51;
    border: 1px solid #ffffff;
    font-size: 14px;
    font-weight: 500;
    border-radius: 8px;
    padding: 10px 20px;
    margin-right: 8px;
    margin-bottom: 8px;
    cursor: pointer;
    transition: background-color 0.2s ease-in-out;
    &:hover {
      background-color: #8bd843;
    }
    &:focus {
      outline: none;
      box-shadow: 0 0 0 4px rgba(167, 235, 81, 0.5);
    }
`;