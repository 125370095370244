import { 
  StepIndicatorContainer, 
  Step, 
  StepCircle, 
  StepLabel, 
  ProgressBar 
} from './styles';

const StepIndicator = ({ steps, currentStep }) => {
  const progressWidth = `calc(${((currentStep + 1) / steps.length) * 100}% - ${(steps.length - 1 - currentStep) * 4}px)`;

  return (
    <StepIndicatorContainer>
      {steps.map((step, index) => (
        <Step key={index} isActive={index <= currentStep}>
          <div style={{ position: 'relative' }}>
            <StepCircle isCompleted={index <= currentStep}>{index + 1}</StepCircle>
          </div>
          <StepLabel>{step}</StepLabel>
        </Step>
      ))}
      <ProgressBar progressWidth={progressWidth} />
    </StepIndicatorContainer>
  );
};

export default StepIndicator;