import { Component } from "react";
import { TitleCustom } from "../../../../../components/titleCustom/TitleCustom";
import { ButtonReturnCustom } from "../../../../../components/ButtonReturnCustom/ButtonReturnCustom";
import { ButtonSaveCustom } from "../../../../../components/ButtonSaveCustom";
import InputCustom from "../../../../../components/InputCustom";
import { DatePickerCustom } from "../../../../../components/DatePickerCustom";

export class CreatePlanoProfissional extends Component {
    render() {
        return <>
            <form>
                <div className="flex items-center justify-between rounded-t dark:border-gray-600">
                    <TitleCustom
                        name="Adicionar Plano De Saúde Profissional"
                    />
                    <ButtonReturnCustom
                        link="/planos"
                    />
                </div>
                <div className="grid gap-6 mb-6 md:grid-cols-2">
                    <InputCustom
                        label="Tipo De Assinatura:"
                        value=""
                        type="text"
                        name="name"
                        placeholder="Tipo De Assinatura"
                    />
                    <InputCustom
                        label="Status De Assinatura:"
                        value=""
                        type="text"
                        name="name"
                        placeholder="Status De Assinatura"
                    />
                    <DatePickerCustom
                        label="Próximo Vencimento:"
                        value=""
                        name=""
                        onChange={() => { }}
                    />
                    <InputCustom
                        label="Valor De Recorrência:"
                        value=""
                        type="number"
                        name="name"
                        placeholder="Valor De Recorrência"
                    />
                    <InputCustom
                        label="Tipo De Recorrência:"
                        value=""
                        type="text"
                        name="name"
                        placeholder="Tipo De Recorrência"
                    />
                    <DatePickerCustom
                        label="Data Início Vigência:"
                        value=""
                        name=""
                        onChange={() => { }}
                    />
                    <DatePickerCustom
                        label="Data Fim Vigência:"
                        value=""
                        name=""
                        onChange={() => { }}
                    />
                    <InputCustom
                        label="Taxa Split Por Operação:"
                        value=""
                        type="number"
                        name="name"
                        placeholder="Taxa Split Por Operação"
                    />
                    <InputCustom
                        label="Desconto Para Planos Paciente:"
                        value=""
                        type="number"
                        name="name"
                        placeholder="Desconto Para Planos Paciente"
                    />
                    <InputCustom
                        label="Carência:"
                        value=""
                        type="number"
                        name="name"
                        placeholder="Dias"
                    />
                </div>
                <div>
                    <InputCustom
                        label="Custo Antecipação:"
                        value=""
                        type="number"
                        name="name"
                        placeholder="Custo Antecipação"
                    />
                </div>
                <div className="flex items-center mt-6 space-x-4 rtl:space-x-reverse">
                    <ButtonSaveCustom
                        type="submit"
                        name="Salvar"
                    />
                </div>
            </form>
        </>
    }
}